const urlWithProtocol = new RegExp('^https?://');

/**
 * @returns {string} URL to MRE host, protocol://host.domain:port.
 *          For example: http://localhost:4567.
 */
export function getAPIHost() {
  return getAPIHostUrl(process.env);
}

/**
 * (Jdeka) - Do we need to export this ?
 * For testing purposes only.
 * @param {{VUE_APP_MRE_HOST: string}} env Environment object, may include
 *         VUE_APP_MRE_HOST property.
 * @returns {string} URL to MRE host, including http protocol.
 */

export function getAPIHostUrl(env) {
  if (env.VUE_APP_CORPDATA_HOST) {
    let host = env.VUE_APP_CORPDATA_HOST;
    if (!urlWithProtocol.test(host)) {
      host = 'https://' + host;
    }
    return host;
  } else {
    throw new Error('Missing environment variable: VUE_APP_CORPDATA_HOST');
  }
}
