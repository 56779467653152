import axios from "axios";
import { getAPIHost } from './apihost';

export function getCorpDataLoad() {
  return axios.get(getAPIHost() + '/v1/corpdata/load/');
}

export function retrieveFinancialCorpDataReport(payload) {
   return axios.post(getAPIHost() + '/v1/corpdata/financial-data-report/', payload , {
       responseType: 'blob',
       headers: { 'Accept': 'application/vnd.ms-excel' }
   });
}
