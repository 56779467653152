<template>
    <div v-if="showSelectDenomination">
        <span style="display: flex; font-weight: bold"> Step 4: Select denomination </span>
        <div id="year-table">
            <div> 
                <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
                <span style="padding-left: 5px;"> Please select the denomination to download results </span>
            </div>
            <div>
                  <div class="label-div">
                      <label > Denomination </label>
                    </div>
                     
                <div id="query-type-div">
                    <form class="mds-form mds-form--medium-layout" method="post" action="form/processor"
                      style="width: 100%; margin: 0 auto;">
                      <div class="mds-form__field-group">
                          <fieldset class="mds-form__radio-button-group" role="radiogroup">
                              <label class="mds-form__radio-button" :for="denomination.code + '-denomination-radio'"
                                v-for="denomination in denominations" :key="denomination.code" >
                                  <input :id="denomination.code + '-denomination-radio'"
                                    :name="denomination.code + '-denomination-radio'" 
                                    :value="denomination.code" type="radio" 
                                    class="mds-form__radio-button-input" 
                                    v-model="denominationSelected"
                                  />
                                  <span class="mds-form__radio-button-visible-wrap">
                                      <span class="mds-form__radio-button-visual"></span>
                                      <span class="mds-form__radio-button-text ">
                                          {{ denomination.name}}
                                      </span>
                                  </span>
                              </label>
                          </fieldset>
                      </div>
                    </form>
                </div>
                <div v-if="showDenominationError">
                    <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px; color: red"> Please select a denomination </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SelectDenomination',
    props: ['showSelectDenomination', 'denominations', 'addSelectedDenomination', 'showDenominationError'],
    data() {
        return {
            denominationSelected: 'millions'
        };
        
    },
    
    computed: {
        
    
    },
    watch: {
        showSelectDenomination: {
            handler: function () {
                if (!this.showSelectDenomination) {
                    this.denominationSelected = 'millions';
                }   
            }
        },
        denominationSelected: {
            handler: function () {
               this.addSelectedDenomination(this.denominationSelected);  
            }
        }
    },
    mounted() {
        
        
    },
    methods: {
        selectDenomination() {
            this.addSelectedDenomination(this.denominationSelected)
        }
    }
}
</script>