<template>
    <div id="complete-reports">
        <div style="float: right">
            <a  href="javascript:void(0)" @click="getCompanyCompletedReports" >Refresh </a>
        </div>
      <table id="complete-reports-table" class="mds-data-table">
          <thead class="mds-data-table__header">
            <tr>
                <th class="mds-data-table__header-cell">
                    <span>  </span>
                </th>
                <th class="mds-data-table__header-cell" width="15%">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Company Name </span>
                        <span class="filter-icon"> <img @click="filters.company.open = !filters.company.open" src="filter-512.png" width="20" height="20"/> </span>
                    </div>
                    <div>
                        <input type="text" v-if="filters.company.open" v-model="filters.company.value"/>
                    </div>
                </th>
                <th class="mds-data-table__header-cell">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Period </span>
                    </div>
                </th>
                <th class="mds-data-table__header-cell" width="22%">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Original Company Report </span>
                    </div>
                </th>
                <th class="mds-data-table__header-cell" width="15%">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Uploaded Date </span>
                    </div>
                </th>
                <th class="mds-data-table__header-cell" width="22%">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Initial Transformation </span>
                    </div>
                </th>
                <th class="mds-data-table__header-cell" width="22%">
                    <div>
                        <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Forecasting Tool </span>
                    </div>
                </th>
            </tr>
          </thead>
          <tbody class="mds-data-table__body">
          
          <tr class="mds-data-table__row" :key="i" v-for="(report, i) in displayedReports" :class="{ 'banded' : report.company }">
            <th class="mds-data-table__cell" width="1%" style="background-color: white !important">
                <span v-if="report.company && !report.opened" >
                   <span style="font-weight: 700;" @click="onClickExpand(report)"> 
                       <i class="fa fa-plus"> 
                       </i>
                   </span>
               </span>
               <span v-if="report.company && report.opened" >
                   <span style="font-weight: 700;" @click="report.opened = false"> 
                        <i class="fa fa-minus"> 
                       </i>
                 </span>
               </span>
            </th>
            <td class="mds-data-table__cell" :class="{ 'centered': report.period } ">
               <span v-if="report.company"> {{ report.company }}  </span>
               <span v-if="report.period && !report.opened" >
                   <span style="font-weight: 700;" @click="reportOpened =''; report.opened = true"> 
                       <i class="fa fa-plus"> 
                       </i>
                   </span>
               </span>
               <span v-if="report.period && report.opened" >
                   <span style="font-weight: 700;" @click="report.opened = false"> 
                        <i class="fa fa-minus"> 
                       </i>
                 </span>
               </span>
            </td>
            <td class="mds-data-table__cell"> 
               <span > {{ report.period }}  </span>
            </td>
            <td class="mds-data-table__cell" width="25%">
               <span style="width: 85%; display: block; float: left; padding-right: 15px"
                    :title="report.originalCompanyReport"> 
                   {{ report.originalCompanyReport | fileName}}
                </span>
               <span class="icon-span" v-if="report.originalCompanyReport"
                    @click="downloadReport('input', report.originalCompanyReport)"> 
                   <i class="fa fa-download fa-lg" aria-hidden="true" style="vertical-align: -3px;">
                </i> </span>
            </td>
             <td class="mds-data-table__cell"> 
               <span > {{ report.uploadedTime }}  </span>
            </td>
            <td class="mds-data-table__cell" width="25%">
               <span style="width: 85%; display: block; float: left; padding-right: 15px"
                    :title="report.initialTransformation" v-if="!report.company && !report.period"> 
                   {{ report.initialTransformation || '--' | fileName}} 
                </span>
                <span class="icon-span" v-if="report.initialTransformation" 
                    @click="downloadReport('process', report.initialTransformation)"> 
                   <i class="fa fa-download fa-lg" aria-hidden="true" style="vertical-align: -3px;">
                </i> </span>

            </td>
            <td class="mds-data-table__cell" width="25%">
              <span style="width: 85%; display: block; float: left; padding-right: 15px"
                 :title="report.forcastingTool"
                 v-if="report.company && !report.period"> 
                  {{ report.forcastingTool || '--' | fileName}} 
              </span>
              <span class="icon-span" v-if="report.company" 
                   @click="downloadReport('output', report.forcastingTool)"> 
                   <i class="fa fa-download fa-lg" aria-hidden="true" style="vertical-align: -3px;">
                </i> </span>
            </td>
          </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
import { pickBy, find, filter, mapValues, keys, concat, uniq, map } from 'lodash';
import { getCompletedReports, getExpandedCompletedReports } from '@/apis/reports';
import { downloadFile } from '@/utils';
import moment from 'moment';

export default {
    name: 'CompletedReports',
    data() {
        return {
            reportOpened: '',
            backendResponse:[ 
            ],
            reports: [],
            filters: {
               'company': {
                   value: '',
                   open: false
                },
                'period': {
                   value: '',
                   open: false
                },
                'originalCompanyReport': {
                   value: '',
                   open: false
                },
                'initialTransformation': {
                   value: '',
                   open: false
                },
                'forcastingTool': {
                   value: '',
                   open: false
                }
            },
            flattenedResponse: [],
            metaddata: {

            },
        };
    },
    props: ['showLoaderToggle', 'toggleRestrictedAccess'],
    watch: {
        displayedReports: {
            handler: function (newValue) {
                let companyNew = newValue.find(company => company.company === this.reportOpened);
                let companyInBackend = this.backendResponse.find(company => company.company === this.reportOpened);
                if (companyNew && !companyNew.fetched && companyNew.opened) {
                    getExpandedCompletedReports(companyNew.id)
                        .then((res) => {
                            let allPeriodsForCompany = map(res.data, 'reportType');
                            let periodsForCompany = uniq(allPeriodsForCompany);
                            periodsForCompany.forEach(periodRes => {
                                let period = {};
                                period.name = periodRes;
                                let reportsForPeriodRes  = res.data.filter(period =>  period.reportType === periodRes)
                                let reportsForPeriodUI = [];
                                reportsForPeriodRes.forEach(report => {
                                    let reportUI = {};
                                    reportUI.original = report.inputFile;
                                    reportUI.initial = report.transformFile;
                                    reportUI.forcasting = companyInBackend.reportName;
                                    reportUI.uploadedTime = report.uploadedTime ? 
                                                                moment(report.uploadedTime).format('MM/DD/YYYY hh:mm:ss') : null;
                                    reportsForPeriodUI.push(reportUI);
                                })
                                period.reports = reportsForPeriodUI;
                                companyInBackend.periods.push(period);
                            });
                            companyNew.fetched = true;
                            this.flattenResponse();
                            this.reports = this.getIntermediate(this.reportOpened);
                        });
                }
            },
            deep: true
        }
    },
    computed: {
        anyFilterOpen() { 
            return this.filters.company.open || this.filters.period.open || this.filters.originalCompanyReport.open ||
                this.filters.initialTransformation.open || this.filters.forcastingTool.open;
        },
        filteredReports() {
            let definedFilters = pickBy(this.filters, filter => filter.value !== '');
            let filterKeyValues = mapValues(definedFilters, 'value');
            let filterKeys = keys(filterKeyValues);
            return filter(this.flattenedResponse, report => {
                let filtersUnmatched = filter(filterKeys , key => {
                    return report[key].toLowerCase().indexOf(filterKeyValues[key].toLowerCase()) === -1;
                });
                return filtersUnmatched.length === 0;

            });
        },
        displayedReports() {
            let allCompanies = filter(this.reports, report => report.company);
            let openedCompanies = filter(this.reports, report => report.company && report.opened === true);
            let displayReportsFinal = [];
            allCompanies.forEach(company => {
                let displayReportsInitial = [];
                displayReportsInitial.push(company);
                if (find(openedCompanies, openCompany => openCompany.company === company.company)) {
                    let filtered = filter(this.reports, report => !report.company && report.bindCompany === company.company) 
                    displayReportsInitial = concat(displayReportsInitial, filtered);
                    let openedPeriods = filter(displayReportsInitial, report => report.period && report.opened === true);
                    displayReportsInitial = filter(displayReportsInitial, report => {
                        return report.company || report.period || find(openedPeriods, period => period.period === report.bindPeriod) !== undefined;
                    });  
                }
                displayReportsFinal = concat(displayReportsFinal, displayReportsInitial);
            });
            return filter(displayReportsFinal, report => report.bindCompany.toLowerCase().indexOf(this.filters.company.value.toLowerCase()) !== -1);
        }
    },
    methods: {
      getIntermediate(companySelected) {
        let vm = this;
        let uniqueCompanies = uniq(map(this.filteredReports, 'company'));
        let intermediateReports = [];
        uniqueCompanies.forEach(company => {
            let existingDisplayed = find(vm.displayedReports, rep => rep.company && !rep.period && rep.company === company);
            let companyFound = find(vm.filteredReports, rep => rep.company && rep.company === company);
            intermediateReports.push({
                'id': companyFound.id,
                'company': company,
                'bindCompany': company,
                'forcastingTool': companyFound.forcastingTool,
                'opened': company === companySelected ? true : (existingDisplayed ? existingDisplayed.opened : false),
                'fetched': company === companySelected ? true : (existingDisplayed ? existingDisplayed.fetched : false)
            });
            let allPeriodsForCompany = filter(map(filter(this.filteredReports , report => report.company === company), 'period'),  elem => elem !== undefined);
            let periodsForCompany = uniq(allPeriodsForCompany);
            if (periodsForCompany && periodsForCompany.length) {
                periodsForCompany.forEach(period => {
                    let existingDisplayedPeriod = find(vm.displayedReports, rep => rep.bindCompany && rep.period && rep.bindCompany === company && rep.period === period);
                    intermediateReports.push({
                        'period': period,
                        'bindCompany': company,
                        'opened': existingDisplayedPeriod ? existingDisplayedPeriod.opened : false,
                    });
                    map(filter(this.filteredReports, {
                        'company': company,
                        'period': period
                    }), report => {
                    intermediateReports.push({
                        'bindCompany': company,
                        'bindPeriod': period, 
                        'originalCompanyReport': report.originalCompanyReport,
                        'initialTransformation': report.initialTransformation,
                        'uploadedTime': report.uploadedTime,
                        'forcastingTool': report.forcastingTool,
                    });  
                    });
                })
            }
        });
        return intermediateReports; 
      },
      onClickExpand(report) {
          this.reportOpened = report.company; 
          report.opened = true
      },
      flattenResponse() {
        this.flattenedResponse = [];
        this.backendResponse.forEach(response => {
            if (response.periods && response.periods.length) {
                response.periods.forEach(period => {
                    period.reports.forEach(report => {
                        this.flattenedResponse.push({
                            'company': response.company,
                            'period': period.name,
                            'originalCompanyReport': report.original,
                            'initialTransformation': report.initial,
                            'uploadedTime': report.uploadedTime,
                            'forcastingTool': report.forcasting
                        });
                    })
                })  
            } else {
               this.flattenedResponse.push({
                    'company': response.company,
                    'id': response.id,
                    'forcastingTool': response.reportName
                }); 
            }
        })
      },
      downloadReport(fileType, fileName) {
        downloadFile(fileType + '/' + fileName)
            .catch(err => {
                console.log(JSON.stringify(err.response));
                this.showErrorAlert(err.response.data.message || err.message);
            });
      },
      getCompanyCompletedReports() {
          this.showLoaderToggle(true);
          getCompletedReports()
            .then(response => {
                response.data.forEach(res => {
                    this.backendResponse.push({
                        'id': res.companyId,
                        'company': res.companyName,
                        'reportName': res.reportName,
                        'periods': [ ]
                    })
                });
                this.flattenResponse();
                this.reports = this.getIntermediate();
                this.showLoaderToggle(false);
            })
            .catch(err => {
                this.showLoaderToggle(false);
                console.log(JSON.stringify(err.response));
                if (err.response.status === 403) {
                   this.toggleRestrictedAccess(true);
                } else {
                    this.showErrorAlert(err.response.data.message || err.message);
                }
            });
      }
    },
    mounted() {
       this.getCompanyCompletedReports();
    }
}
</script>
<style>
.filter-row td {
    text-align: center;
}
.forcasting-tool button {
    height: 36px;
}

#complete-reports {
   padding-bottom: 5%  
}
#complete-reports-table tr th:first-child {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
}

.centered {
    text-align: center;
    cursor: pointer;
}

.banded {
    background-color: #dddddd;
}

.banded td {
    padding-top: 14px;
}

.banded th {
    padding-top: 14px;
}

#complete-reports th div {
   display: flex;
}

#complete-reports th input {
   margin-bottom: 10px;
}

.company-row td {
    padding-top: 14px;
}

#complete-reports td span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-span {
    margin-right: 5px;
    cursor: pointer;
    display: inline-block;
    margin-top: 4px;
    overflow: visible !important;
}
</style>