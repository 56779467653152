<template>
    <div id="peercomp">
      <table id="peer-group-reports" class="mds-data-table">
          <thead class="mds-data-table__header">
          <tr>
            <th class="mds-data-table__header-cell" width="40%">
                <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> 
                    Methodology
                </span>
            </th>
            <th class="mds-data-table__header-cell" width="40%">
                <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> 
                    Company
                </span>
            </th>
            <th class="mds-data-table__header-cell" width="20%">
                <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> 
                    Year
                </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :key="i" v-for="(report, i) in reports"> 
            <td>
                <Multiselect label="name" track-by="code" 
                      v-model="report.sector"
                      :options="methodologies"
                      @select="report.company = null; report.year = null"
                      placeholder="Select a methodology"
                      :class="{ 'error': report.sectorError}"
                    >
                  </Multiselect>
            </td>
            <td>
               <Multiselect :disabled="!report.sector" label="name" track-by="id" 
                          :options="report.sector ? report.sector.companies : []"
                          v-model="report.company"
                          placeholder="Select a company"
                          @select="report.year = null"
                          @input="fetchForcastedYears(report)"
                          :class="{ 'error': report.companyError}"
                      >
                      </Multiselect>
            </td>
            <td>
              <Multiselect v-model="report.year" label="name" track-by="code" 
                        :options="report.company && report.company.years ? report.company.years : []"
                        :disabled="!report.company"
                        placeholder="Select a year"
                        :class="{ 'error': report.yearError}">
                    </Multiselect>
            </td>
          </tr>
          </tbody>
        </table>
        <button class="mds-button mds-button--secondary" style="float: left; margin-top: 10px" @click="addRow"> Add Row </button>
        <div style="margin-top: 50px">
            <button @click="compare"> Compare </button>

        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { getForcastedYears } from '@/apis/companies';
import { getPeerCompanyReport } from '@/apis/reports';

export default {
    name: 'PeerComp',
    data() {
        return {
            reports: []
        };
    },
    props: ['methodologies', 'showLoaderToggle', 'showErrorAlert'],
    components: { Multiselect },
    methods: {
        addRow() {
            if (this.reports.length === 20) {
                this.showErrorAlert('Maximum of 20 rows can be added.');
                return;
            }
            this.reports.push({
                'company': null,
                'sector': null,
                'year': null
            })
        },
        validateInputs() {
            let anyError = false;
            this.reports.forEach(rep => {
                if (!rep.sector) {
                    anyError = true;
                    rep.sectorError = true;
                } else {
                    rep.sectorError = false;
                }
                if (!rep.company) {
                    anyError = true;
                    rep.companyError = true;
                } else {
                    rep.companyError = false;
                }
                if (!rep.year) {
                    anyError = true;
                    rep.yearError = true;
                } else {
                    rep.yearError = false;
                }
            });
            this.$forceUpdate();
            return !anyError;
        },
        compare() {
            if (this.reports.length < 2) {
                this.showErrorAlert('Please add atleast 2 rows to compare');
                return;
            }
            if (!this.validateInputs()) {
                this.showErrorAlert('Please input the fields marked in red');
                return;
            }
            let reportsForPeerComp = this.reports.map(report => {
                return {
                    'company_id': report.company.id,
                    'company_name': report.company.name,
                    'sector': report.sector.name,
                    'year': report.year.name
                };
            });
            let payload = {
                'peerfilter' : reportsForPeerComp
            };
            this.showLoaderToggle(true);
            getPeerCompanyReport(payload)
                .then((response) => {
                    this.showLoaderToggle(false);
                    const blob = new Blob([response.data], { type: response.data.type });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Peer_Comp_Report.csv');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    this.showLoaderToggle(false);
                    console.log(JSON.stringify(err.response));
                    this.showErrorAlert(err.response.data.message || err.message);
                });
        },
        removeRow(reportToRemove) {
            this.reports = this.reports.filter(rep => rep.company !== reportToRemove.company);
            this.showErrorAlert('Row was removed since the company does not have forcasted years. Please select another company for comparision.')
        },
        isCompanyAlreadySelected(companyId) {
            let companies = this.reports.filter(rep => rep.company.id === companyId);
            return companies.length > 1;
        },
        fetchForcastedYears(report) {
            if (this.isCompanyAlreadySelected(report.company.id)) {
                this.showErrorAlert("Company was already selected for comparison. Please select a different company");
                report.company = null;
                return;
            }
            this.showLoaderToggle(true);
            getForcastedYears(report.company.id)
                .then(res => {
                    report.company.years = res.data.map((year, index) => {
                        return {
                            name: 'F' + (index + 1) + ' ' + year, 
                            code: 'F' + index + ' ' + year
                        }
                    });
                    if (report.company.years.length > 0) {
                        report.year = report.company.years[0];
                    } else {
                        this.removeRow(report);
                    }
                    this.$forceUpdate();
                    this.showLoaderToggle(false);
                })
                .catch(err => {
                    this.showLoaderToggle(false);
                    console.log(JSON.stringify(err.response));
                    this.showErrorAlert(err.response.data.message || err.message);
                });
        }
    }

}
</script>
<style>
.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: none !important;
}
</style>