import { getDownloadReportURL } from '@/apis/reports';

export function browse(selectedFileElementId, uploadBtnElementId) {
    document.getElementById(uploadBtnElementId).value = null;
    document.getElementById(uploadBtnElementId).click();
}

export function showSelectedFile(selectedFileElementId, uploadBtnElementId) {
    document.getElementById(selectedFileElementId).innerHTML = 
      document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
    document.getElementById(selectedFileElementId).title = 
      document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
  }
export function downloadFile(fileKey) {
    return getDownloadReportURL(fileKey)
      .then(res => {
        location.assign(res.data["download_url"]);
      });
  }