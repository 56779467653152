<template>
    <div  class="mds-empty-state">
        <div class="mds-empty-state__title">
            Restricted Access
        </div> 
        <div class="mds-empty-state__icon"> 
            <img src="restricted.jpg" id="menu-carrot-down"/>
        </div>
        <div class="mds-empty-state__message">
            You do not have access to this page
        </div>
    </div>
</template>
<script>
export default {
  name: 'RestrictedAccess'
}
</script>
