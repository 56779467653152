import axios from 'axios';
import { getAPIHost } from './apihost';

export function getAllCompanies() {
  return axios.get(getAPIHost() + '/getcompanyinfo/');
}

export function searchCompanies(payload) {
  return axios.post(getAPIHost() +  '/v1/corpdata/company-names', payload)
}

export function getForcastedYears(companyId) {
  return axios.get(getAPIHost() + '/v1/corpdata/get_forecasting_year?compnay_id=' + companyId);
}