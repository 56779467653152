<template>
    <div v-if="showSelectLineItemsRatios">
      <span style="display: flex; font-weight: bold"> Step 3: Select line item(s) and ratio(s) to retrieve</span>
      <div id="line-items-table"> 
        <div style="display: flex; margin-bottom: 8px;">
            <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
            <span style="padding-left: 5px;"> Please select atleast 1 line item across the 3 categories </span>
        </div>
        <div v-if="showLineItemError" style="display: block; text-align: left">
            <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
            <span style="padding-left: 5px; color: red"> Please select atleast 1 line item </span>
        </div>
        <div class="line-item-container">
            <div colspan="1" :key="val.code" v-for="val in lineItems">
                  <div class="label-div">
                    <label > {{ val.name }} </label>
                  </div>
                  <div>
                  <Multiselect :id="val.code" v-model="val.selectedLineItems" label="name" track-by="code" 
                      :options="[allOption].concat(val.items)" :multiple="true"
                      placeholder="Select line item(s)"
                      selectLabel="Select line item"
                      deselectLabel="Remove line item"
                      :closeOnSelect="false"
                      @input="selectLineItem"
                      :class="{ 'error': showLineItemError}"
                  >
                  </Multiselect>
                  </div>  
          </div>
        </div>
        <div id="methodology-ratios-table" style="display: flex;">
          <div class="label-div">
              <label > Ratios </label>
            </div>
            <div style="display: block">
              <Multiselect label="name" track-by="code" 
                  v-model="ratiosSelected"  
                  :closeOnSelect="false"
                  :options="[allOption].concat(ratios)" :multiple="true"
                  placeholder="Select one or more ratios"
                  selectLabel="Select ratio"
                  deselectGroupLabel="Remove ratio"
                  @input="selectRatio"           
                >
              </Multiselect>
            </div>
        </div>
      </div> 
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
    name: 'SelectLineItemsRatios',
    props: ['showSelectLineItemsRatios', 'lineItems', 'ratios', 
               'allOption', 'addSelectedLineItem', 'addSelectedRatio','showLineItemError'],
    data() {
        
        return {
            ratiosSelected: []
        };
        
    },
    
    computed: {
        lineItemsInternal() {
            return this.lineItems;
        }
    
    },
    watch: {
        showSelectLineItemsRatios: {
            handler: function () {
                if (!this.showSelectLineItemsRatios) {
                    this.ratiosSelected = [];
                    this.lineItems.forEach(lineItem => {
                        lineItem.selectedLineItems = [];
                    })
                    this.lineItemsInternal.forEach(lineItem => {
                        lineItem.selectedLineItems = [];
                    })
                }   
            }
        }
    },
    mounted() {
        
        
    },
    methods: {
        selectRatio(ratios) {
            if (ratios.find(val => val.code === 'all')) {
                this.ratiosSelected = this.ratios.filter(val => val.code !== 'all');
            } else {
                this.ratiosSelected = ratios;
            }
           this.addSelectedRatio(this.ratiosSelected);
        },
        selectLineItem(lineItems, id) {
           let lineItem = this.lineItems.find(val => val.code === id); 
           if (lineItems.find(val => val.code === 'all')) {
                lineItem.selectedLineItems = lineItem.items;
            } else {
                lineItem.selectedLineItems = lineItems;
            }
        }
    },
    components: {
        Multiselect
    }
}
</script>