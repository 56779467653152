<template>
  <div id="app" @click="closeMenu">
    <header data-v-52991004="" class="mds-masthead" style="z-index: 1009;">
      <div data-v-52991004="" class="mds-masthead__inner mre-fixed-header"
        >
          <div data-v-52991004="" 
            id="menu"
            @click="openMenu = true"
            style="width: 22%;"
            class="mds-popover__wrapper mds-popover--overlay-closable 
              mds-popover--overlay-dismissible"
              :class="{'mds-popover__wrapper--visible': openMenu}"
              >
            <button data-v-52991004="" type="button"
              data-mds-popover="mds-popover--33622" aria-haspopup="true" aria-expanded="true" class="mds-button mds-button--flat" style="z-index: 800;">
                <div style="float: left" > <img src="home.png" id="menu-home"/> </div>
                <span data-v-52991004="" id="menu-text" class="mds-button__text" style="float: left; font-size: 24px; margin-left: 5px;"> {{ activeTab.name }} </span>
                <div style="float: left" > 
                  <img src="carrot-down.png" id="menu-carrot-down"/> 
                </div>
            </button>
            <section v-if="openMenu" id="mds-popover--69433" class="mds-popover mds-popover--bottom-right mds-popover--width-200px" role="tooltip">
              <div class="mds-popover__content">
                  <ul  class="mds-list-group">
                    <li v-for="tab in allowedTabs" :key="tab.name" class="mds-list-group__item"
                      :class="{ 'mds-list-group__item--active': activeTab.name === tab.name}"
                      @click="activeTab = tab">
                      <router-link class="mds-list-group__link mds-button mds-button--medium mds-button--flat 
                      mds-site-navigation__button no-focus" style="text-align: left; display: block;" :to="tab.routerLink">
                      <span data-v-52991004=""> {{ tab.name }} </span>
                      <svg v-if="activeTab.name === tab.name" data-v-52991004="" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 28 28" class="mds-icon mds-list-group__active-icon">
                      <path data-v-52991004="" 
                      d="M23.529 3.724l-12.32 17.733-6.906-6.72-1.307 1.307 8.4 8.214L25.022 4.657z">
                      </path></svg></router-link>
                    </li>
                  </ul>
              </div>
          </section>
          </div>
          <div data-v-52991004="" class="mds-masthead__logo-wrap" style="margin-left: 22%"><a data-v-52991004="" 
            href="/" class="mds-masthead__link router-link-exact-active router-link-active">
            
            <img
                src="mstardbrs.png" 
                width="350" 
                height="50"
            />
            </a></div>
          <div data-v-52991004="" class="mds-masthead__right">
            <i class="fa fa-lg fa-user" aria-hidden="true"></i>
            <button data-v-52991004="" type="button" @click="logout()"
              class="mds-button mds-button--flat mds-button--flat-icon-m mds-masthead--show-wide">
                <span data-v-52991004="" class="mds-button__text"> Sign Out </span>
            </button>
          </div>
      </div>
    </header>

    <div v-if="showLoader" id="loader" class="loader">
      <span>
        <i class="fa fa-spinner fa-spin main-loader"></i>
      </span>
    </div>
    <div v-if="showLoader"  id="overlay" class="overlay"></div>
    <div class="alert" v-if="errAlertVisible">
      <div class="mds-alert mds-alert--tinted mds-alert--error" role="alert">
          <div class="mds-alert__cell-icon">
             <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
          </div>
          <div class="mds-alert__cell-body">
              <aside class="mds-alert__content">
                  <span class="mds-alert__message"> {{ errAlertMessage }} </span>
              </aside>
              <aside class="alert-close"> <span class="close" @click="errAlertVisible = false">×</span> </aside>
          </div>   
      </div>
    </div>
    <div class="alert" v-if="successAlertVisible">
      <div class="mds-alert mds-alert--tinted mds-alert--success" role="alert">
          <div class="mds-alert__cell-icon">
             <span > <i class="fa fa-lg fa-check" aria-hidden="true"> </i> </span>
          </div>
          <div class="mds-alert__cell-body">
              <aside class="mds-alert__content">
                  <span class="mds-alert__message"> {{ successAlertMessage }} </span>
              </aside>
          </div>   
      </div>
    </div>
    
    <!--
    <div style="margin: 0 auto;">
      <div style="float: left; padding-right: 20px; width: 20%; text-align: center;">
        <div class="uploadHeader"> Original Company Report</div>
        <button @click="showInputModal = true">Input Details</button>
      </div>

      <div style="float: left; padding-right: 20px; width: 20%; text-align: center;">
        <div class="uploadHeader"> Update Company Report</div>
        <button @click="showUpdateModal = true">Input Details</button>
      </div>
    </div>
    <div>
      <span>
        <a href="#" @click="viewAll()" style="float: right; padding-right: 5px;">
          Refresh
        </a>
      </span>
      <span>
        <router-link style="float: right; padding-right: 5px;" to="/retrieval">
           Retrieval
        </router-link>
      </span>
    </div>
    <br />
    -->
    <div id="report" v-show="false">
      <table id="reports">
          <tr>
            <th colspan=3>Original Company Report</th>
            <th colspan=3>Initial Transformation</th>
            <th colspan=3>Cleaned and Standardized statements</th>
          </tr>
          <tr :key="i" v-for="(report, i) in reports"> 
            <td style="border-right-style: hidden">
                {{ report.input.fileName }}
            </td>
            <td style="border-right-style: hidden">
                {{ report.input.timestamp.format('MM/DD/YYYY HH:mm:ss') }}
            </td>
            <td>
              <img src="icons8-download-24.png" @click="download(report.input.fileKey)"/>
            </td>
            
            <td v-if="report.process" style="border-right-style: hidden">
              {{ report.process.fileName }}
            </td>
            <td v-if="!report.process && report.showLoadingProcess" 
              colspan="3" style="text-align: center">
              <span class="buttonload">
                <i class="fa fa-spinner fa-spin" style="float: left; margin-top: 3px"></i>
                <span> Generating file... </span>
              </span>
            </td>
            <td v-if="!report.process && !report.showLoadingProcess" 
              colspan="3" style="text-align: center">
              <span class="buttonload">
                <i class="material-icons" style="font-size:24px;color:red; float: left; margin-top: 3px">error</i>
                <span> Failed to generate file. </span>
              </span>
            </td>
            <td v-if="report.process && report.process.timestamp" style="border-right-style: hidden">
              {{ report.process.timestamp.format('MM/DD/YYYY HH:mm:ss') }}
            </td>
            <td v-if="report.process && report.process.fileKey">
              <img src="icons8-download-24.png" @click="download(report.process.fileKey)">
            </td>

            <td v-if="report.output" style="border-right-style: hidden">
              {{ report.output.fileName }}
            </td>
            <td v-if="!report.output && report.showLoadingOutput" 
              colspan="3" style="text-align: center">
              <span class="buttonload">
                <i class="fa fa-spinner fa-spin" style="float: left; margin-top: 3px"></i>
                <span> Generating file... </span>
              </span>
            </td>
            <td v-if="!report.output && !report.showLoadingOutput"
              colspan="3" style="text-align: center">
              <span class="buttonload">
                <i class="material-icons" style="font-size:24px;color:red; float: left; margin-top: 3px">error</i>
                <span> Failed to generate file. </span>
              </span>
            </td>
            <td v-if="report.output && report.output.timestamp" style="border-right-style: hidden">
              {{ report.output.timestamp.format('MM/DD/YYYY HH:mm:ss') }}
            </td>
            <td v-if="report.output && report.output.fileKey">
              <img src="icons8-download-24.png" @click="download(report.output.fileKey)">
            </td>
          </tr>
        </table>
    </div>
    <div v-if="restrictedAccess" class="restricted-access-container">
      <RestrictedAccess />
    </div>
    <router-view v-if="!restrictedAccess" :showSuccessAlert="showSuccessAlert" 
      :showErrorAlert="showErrorAlert" :showLoaderToggle="showLoaderToggle"
      :changeActiveTab="changeActiveTab" :companyInfo="companyInfo"
      :toggleRestrictedAccess="toggleRestrictedAccess"
      :getCompanies="getCompanies">
    </router-view>
  </div>
  
</template>

<script>

import { BUCKET_DETAILS } from './constants';
import { getAllCompanies } from '@/apis/companies';
import '@/filters.js';
import { logoutUim, redirectToCreditLogin } from '@/apis/userauthentication';
import { getUserRoles } from '@/apis/userroles';
import { uniqBy } from 'lodash';
import RestrictedAccess from '@/components/RestrictedAccess';
import './mds.scss';

export default {
  name: 'App',
  data() {
    return {
      bucketName: BUCKET_DETAILS.bucketName,
      bucketRegion: BUCKET_DETAILS.bucketRegion,
      IdentityPoolId: BUCKET_DETAILS.IdentityPoolId,
      continueRefresh: false,
      s3: null,
      showLoader: false,
      reports: [],
      showInputModal: false,
      showUpdateModal: false,
      showCompareSectorsModal: false,
      successAlertVisible: false,
      errAlertVisible: false,
      modalErrorAlertVisible: false,
      successAlertMessage: '',
      errAlertMessage: '',
      modalErrAlertMessage: '',
      companies: [],
      sectors: [],
      companyInfo: null,
      openMenu: false,
      activeTab: {},
      userRoles: [],
      homeTab: {},
      restrictedAccess: false
    }
  },
  mounted() {
    getUserRoles()
      .then(res => {
        this.getCompanies();
        this.userRoles = res.data;
      })
      .catch(err => {
        console.log(JSON.stringify(err.response));
        if (err.response.status === 403) {
          this.restrictedAccess = true;
        } else {
          this.showErrorAlert(err.response.data.message || err.message);
        }
      });

  },
  components: {
    RestrictedAccess
  },
  computed: {
    allowedTabs: function () {
        let tabs = [{
          name: 'Home',
          routerLink: '/home',
          isVisible: false
        }, {
          name: 'Reporting Tool',
          routerLink: '/reporting-tool',
          isVisible: false
        }, {
          name: 'Config',
          routerLink: '/config',
          isVisible: false
        }];
      this.userRoles.forEach(role => {
        if (role === 'admin') {
          tabs.forEach(tab => tab.isVisible = true);
        } else if (role === 'operator') {
          tabs.find(tab => tab.name === 'Reporting Tool').isVisible = true;
        }
      });
      return uniqBy(tabs.filter(tab => tab.isVisible), 'name');
    }
  },
  watch: {
    allowedTabs: function() {
      this.homeTab = this.allowedTabs.find(tab => tab.name === 'Home');
      if (!this.homeTab) {
        this.homeTab = this.allowedTabs.find(tab => tab.name === 'Reporting Tool');
      }
      
      if (this.$route.path === '/') {
        this.activeTab = this.homeTab;
        this.$router.push(this.activeTab.routerLink);
      }
    },
    $route: function() {
      this.restrictedAccess = false;
    }
  },
  methods: {
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    browse(selectedFileElementId, uploadBtnElementId) {
      document.getElementById(selectedFileElementId).innerHTML = '';
      document.getElementById(selectedFileElementId).title = '';
      document.getElementById(uploadBtnElementId).click();
    },
    showSelectedFile(selectedFileElementId, uploadBtnElementId) {
      document.getElementById(selectedFileElementId).innerHTML = 
        document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
      document.getElementById(selectedFileElementId).title = 
        document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
    },
    showSuccessAlert(alertMessage) {
      this.successAlertVisible = true;
      this.successAlertMessage = alertMessage;
      let _this = this;
      setTimeout(() => {
        _this.successAlertVisible = false;
      }, 5000);
    },
    showErrorAlert(alertMessage) {
      this.errAlertVisible = true;
      this.errAlertMessage = alertMessage;
    },
    showLoaderToggle(flag) {
      this.showLoader = flag;
    },
    toggleRestrictedAccess(flag) {
      this.restrictedAccess = flag;
    },
    logout() {
      logoutUim().then(data => {
        if (data && data['result']) {
          const successUrl = new URL(window.location.href);
          const infoMg = "You've been successfully logged out.";

          redirectToCreditLogin(successUrl, infoMg);
        }
      });
    },
    clear(uploadElementId, fileNameSelectedElemId) {
      document.getElementById(uploadElementId).value = null;
      document.getElementById(fileNameSelectedElemId).innerHTML = '';
      document.getElementById(fileNameSelectedElemId).title = '';
    }, 
    validateInputs() {
      if (this.companyName) {
        return true;
      } else {
        return false;
      }
    },
    closeModal(modalName) {
      if (modalName === 'input-details') {
        this.showInputModal = false;
      } else if (modalName === 'update-details') {
        this.showUpdateModal = false;
      } else if (modalName === 'compare-sectors') {
        this.showCompareSectorsModal = false;
      }
    },
    getCompanies() {
      return getAllCompanies()
        .then(res => {
          this.companyInfo = res.data;
        });
    },
    binArrayToJson(binArray) {
      var str = "";
      for (var i = 0; i < binArray.length; i++) {
        str += String.fromCharCode(parseInt(binArray[i]));
      }
      return JSON.parse(str);
    },
    JsonToArray(json) {
      var str = JSON.stringify(json, null, 0);
      var ret = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        ret[i] = str.charCodeAt(i);
      }
      return ret
    },
    closeMenu(evt) {
      if(evt.target.id !== 'menu-text' && evt.target.id !== 'menu-home'  && evt.target.id !== 'menu-carrot-down' ) {
        this.openMenu = false;
      }
    },
    checkExistingCompany(company) {
      var upperCaseCompanies = this.uiConfigFile.companies.map(function(value) {
        return value.toUpperCase();
      });
      return upperCaseCompanies.indexOf(company.toUpperCase()) !== -1;
    },
    addNewCompany(uiCompanyId, companyName, res) {
      if(uiCompanyId === 0) {
        this.companies.push({'company_id': res.data['company_id'], 'company_name': companyName});
      }
      return Promise.resolve(res);
    }
  }
}
</script>

<style lang="scss">
      @import '@/mds.scss';

      #app {
        text-align: center;
        height: 100%;
      }
      body {
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375;
        font-size: 16px;
        font-weight: 300;
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      #reports tr:nth-child(even) {
        background-color: #dddddd;
      }

      td:nth-child(3n - 1) {
        width: 8%;
      }

      td:nth-child(3n - 2) {
        width: 21%;
      }

      td:nth-child(3n) {
        width: 4%;
      }

      img {
        cursor: pointer;
      }

      .browseBtn {
        width: auto;
        margin-right: 10px;;
      }

      .uploadBtn {
        width: auto;
      }

      button {
        box-shadow: inset 0 0 0 1px #0077cf;
        background-color: #0077cf;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        line-height: 1.25;
        font-size: 16px;
        background-clip: padding-box;
        border: 0;
        border-radius: 24px;
        cursor: hand;
        display: inline-block;
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        margin: 0;
        outline: 0;
        overflow: visible;
        padding: 8px 16px;
        position: relative;
        text-align: center;
        -webkit-transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,-webkit-box-shadow .2s linear;
        transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,box-shadow .2s linear,-webkit-box-shadow .2s linear;
      }

      .fa-spinner:before {
        font-size: x-large;
      }

      .buttonload span {
        padding-left: 10px;
        padding-top: 6px;
        float: left;
      }

      .buttonload {
        display: inline-block;
      }

      .fileNameSelected {
        max-width: 70%;
        padding-right: 10px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }

      .alert {
          width: 40%;
          position: fixed;
          left: 30%;
          top: 8px;
          z-index: 2;
      }

      .success {
          width: 40%;
          position: fixed;
          left: 30%;
          top: 8px;
          z-index: 2;
      }

      .closebtn {
        margin-left: 15px;
        color: white;
        font-weight: bold;
        float: right;
        font-size: 22px;
        line-height: 20px;
        cursor: pointer;
        transition: 0.3s;
      }

      .uploadHeader {
        text-align: center;
        font-weight: bold; 
        font-size: large;
        padding-bottom: 5px;
      }

      .closebtn:hover {
        color: black;
      }
      .overlay {
        width: 100%;
        height: 100%;
        z-index: 801;
        position: fixed;
        opacity: 0.6;
        top: 0;
        background-color: rgba(255,255,255,0.7);
      }

      .loader {
        top: 42%;
        left: 48%;
        position: fixed;
        z-index: 2;
      }
      .main-loader:before {
        font-size: 64px;
      }
      .main-loader {
        width: 64px;
        height: 64px;
      }

      /* The Modal (background) */
      .modal {
        position: fixed; /* Stay in place */
        z-index: 10000; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      }

      /* Modal Content/Box */
      .modal-content {
        border: 1px solid #bdbdbb;
        padding: 2%;
      }

      /* The Close Button */
      .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
      }

      .close:hover,
      .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }

      input[type=text] {
        padding: 0;
        height: 30px;
        position: relative;
        left: 0;
        outline: none;
        border: 1px solid #cdcdcd;
        border-color: rgba(0, 0, 0, .15);
        background-color: #dddddd;
        font-size: 16px;
        padding-left: 5px;
      }

      .advancedSearchTextbox {
        margin-right: -4px;
      }
      /* The container */
      .container {
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-right: 8px;
      }

      /* Hide the browser's default radio button */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: -2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #dddddd;
        border-radius: 50%;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #dddddd;
      }

      /* When the radio button is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: #2196F3;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      .container .checkmark:after {
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }

      .required:after {
        content:" *";
        color: red;
      }

      .mds-popover__wrapper {
          display: inline-block;
          position: relative;
      }

      .mds-button, .mds-button--secondary {
    line-height: 1.25;
    -webkit-box-shadow: inset 0 0 0 1px grey;
    box-shadow: inset 0 0 0 1px grey;
    font-size: 16px;
    background-clip: padding-box;
    background-color: transparent;
    border: 0;
    border-radius: 24px;
    color: #5e5e5e;
    cursor: pointer;
    display: inline-block;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    margin: 0;
    outline: 0;
    overflow: visible;
    padding: 8px 16px;
    position: relative;
    text-align: center;
    -webkit-transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,-webkit-box-shadow .2s linear;
    transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,box-shadow .2s linear,-webkit-box-shadow .2s linear;
}

.mds-popover.mds-popover--bottom-right {
    margin-top: 8px;
    top: 100%;
    border-top-left-radius: 0;
    left: 50%;
    border: 1px solid #ccc;
}

.mds-popover__wrapper--visible .mds-popover {
    opacity: 1;
    visibility: visible;
}
.mds-popover {
    z-index: 20;
}

.mds-popover {
    background-color: #fff;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    color: #1e1e1e;
    display: block;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    opacity: 0;
    padding: 16px;
    position: absolute;
    text-align: left;
    text-decoration: none;
    -webkit-transition: opacity .2s linear,visibility .2s linear;
    transition: opacity .2s linear,visibility .2s linear;
    visibility: hidden;
    z-index: 1;
}

.mds-popover__wrapper--visible .mds-popover {
    opacity: 1;
    visibility: visible;
}

.mds-popover.mds-popover--bottom-right:after {
    border-bottom: 8px solid #fff;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    bottom: 100%;
    margin-bottom: -1px;
    border-left: 0;
    border-right: 8px solid transparent;
    left: 0;
}

.mds-popover.mds-popover--bottom-right:before {
    border-bottom: 9px solid #ccc;
    content: '';
    height: 0;
    position: absolute;
    width: 0;
    bottom: 100%;
    border-left: 0;
    border-right: 9px solid transparent;
    left: -1px;
}

.mds-popover--width-200px {
    width: 200px;
}

.mds-list-group {
    font-size: 16px;
    display: block;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    list-style: none;
    margin: 0 0 8px;
    padding: 0;
    width: 100%;
}

.mds-list-group__item {
    display: block;
    margin: 0;
    position: relative;
}

.mds-list-group__item--active .mds-list-group__link, .mds-list-group__item--active .mds-list-group__link:visited {
    padding-right: 23px;
}

.mds-container--light-gray .mds-list-group__item--active .mds-list-group__link, .mds-container--light-gray .mds-list-group__item--active .mds-list-group__link:visited, .mds-container--white .mds-list-group__item--active .mds-list-group__link, .mds-container--white .mds-list-group__item--active .mds-list-group__link:visited, .mds-list-group__item--active .mds-list-group__link, .mds-list-group__item--active .mds-list-group__link:visited {
    color: #006fba;
}

a.mds-button {
    text-decoration: none;
}

.mds-container--black .mds-list-group__link, .mds-container--dark-gray .mds-list-group__link, .mds-container--light-gray .mds-list-group__link, .mds-container--white .mds-list-group__link, .mds-list-group__link {
    border-bottom: 0;
}

.mds-container--white .mds-popover .mds-list-group__item, .mds-popover .mds-list-group__item {
    border-top: 1px solid #e5e5e5;
}

.mds-popover, .mds-popover *, .mds-popover:after, .mds-popover :after, .mds-popover:before, .mds-popover :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mds-button--flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 4px;
    padding-right: 4px;
}

.mds-list-group__active-icon {
    height: 15px;
    margin-top: -1px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 8px;
    -webkit-transition: opacity .2s cubic-bezier(.5,0,.5,1);
    transition: opacity .2s cubic-bezier(.5,0,.5,1);
    width: 15px;
}

.mds-container--light-gray .mds-list-group__active-icon, .mds-container--white .mds-list-group__active-icon, .mds-list-group__active-icon {
    fill: #006fba;
}

.mds-list-group__item--active .mds-list-group__active-icon {
    opacity: 1;
}

.mds-list-group__item--active .mds-list-group__link, .mds-list-group__item--active .mds-list-group__link:visited {
    padding-right: 23px;
}

.mds-popover .mds-list-group__item:first-child {
    border-top: 0;
}

.mds-masthead {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: no-wrap;
    flex-wrap: no-wrap;
    height: 50px;
    padding: 16px;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
}

.mds-masthead__inner {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.mds-masthead__inner, .mds-masthead__left, .mds-masthead__right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: no-wrap;
    flex-wrap: no-wrap;
}

.mds-masthead, .mds-masthead *, .mds-masthead:after, .mds-masthead :after, .mds-masthead:before, .mds-masthead :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mds-popover__wrapper {
    display: inline-block;
    position: relative;
}

.mds-masthead, .mds-masthead *, .mds-masthead:after, .mds-masthead :after, .mds-masthead:before, .mds-masthead :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mds-button--flat, .mds-container--light-gray .mds-button--flat, .mds-container--white .mds-button--flat {
    color: #5e5e5e;
}

.mds-button--flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 4px;
    padding-right: 4px;
}

.mds-button, .mds-button--secondary {
    line-height: 1.25;
    -webkit-box-shadow: inset 0 0 0 1px grey;
    box-shadow: inset 0 0 0 1px grey;
    font-size: 16px;
    background-clip: padding-box;
    background-color: transparent;
    border: 0;
    border-radius: 24px;
    color: #5e5e5e;
    cursor: pointer;
    display: inline-block;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    margin: 0;
    outline: 0;
    overflow: visible;
    padding: 8px 16px;
    position: relative;
    text-align: center;
    -webkit-transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,-webkit-box-shadow .2s linear;
    transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,-webkit-box-shadow .2s linear;
    transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,box-shadow .2s linear;
    transition: background .2s linear,color .2s linear,border .2s linear,fill .2s linear,box-shadow .2s linear,-webkit-box-shadow .2s linear;
}

.mre-fixed-header[data-v-52991004] {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    height: 50px
}

.mds-masthead, .mds-masthead *, .mds-masthead:after, .mds-masthead :after, .mds-masthead:before, .mds-masthead :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


.mds-icon {
    height: 18px;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    width: 18px;
}

.mds-button--flat {
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 4px;
    padding-right: 4px;
}

.mds-masthead__logo-wrap {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 16px;
    padding-bottom: 0;
    padding-top: 0;
}

.mds-masthead__logo--wide {
    max-height: 50px;
}

.mds-masthead__logo--wide {
    display: block;
}

.mds-container--white .mds-data-table__cell, .mds-data-table__cell {
    border-top: 1px solid #e5e5e5;
}

.mds-data-table, .mds-data-table *, .mds-data-table:after, .mds-data-table :after, .mds-data-table:before, .mds-data-table :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mds-data-table__cell:first-child {
    padding-left: 0;
}

.mds-form, .mds-form *, .mds-form :after, .mds-form :before, .mds-form:after, .mds-form:before, .mds-form__field-group, .mds-form__field-group *, .mds-form__field-group :after, .mds-form__field-group :before, .mds-form__field-group:after, .mds-form__field-group:before, .mds-form__fieldset, .mds-form__fieldset *, .mds-form__fieldset :after, .mds-form__fieldset :before, .mds-form__fieldset:after, .mds-form__fieldset:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.mds-form--medium-layout .mds-form__field-group, .mds-form--medium-layout .mds-form__required-fields-key {
    margin: 0 0 24px;
}

.mds-form__field-group--horizontal>:last-child {
    margin-right: 0;
}

.mds-form__field-group--horizontal>* {
    margin-bottom: 0;
}

.mds-form__radio-button-group {
    border: 0;
    margin: 0;
    padding: 0;
}

.mds-form__radio-button-group-label {
    font-size: 16px;
    color: #1e1e1e;
    display: block;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1.2;
    padding: 0;
}

.mds-form--medium-layout .mds-form__checkbox-group-label, .mds-form--medium-layout .mds-form__label, .mds-form--medium-layout .mds-form__radio-button-group-label {
    margin: 0 0 12px;
}

.mds-form__radio-button {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

.mds-form__field-group--horizontal .mds-form__radio-button {
    display: inline-block;
}

.mds-form--medium-layout .mds-form__field-group--horizontal .mds-form__checkbox-group>.mds-form__checkbox, .mds-form--medium-layout .mds-form__field-group--horizontal .mds-form__radio-button-group>.mds-form__radio-button, .mds-form--medium-layout .mds-form__field-group--horizontal>.mds-form__field-group {
    margin: 0 12px 0 0;
}

.mds-form__radio-button-input {
    clip: rect(0 0 0 0);
    left: 0;
    position: absolute;
    z-index: -1;
}

.mds-form__field-group--horizontal {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mds-form__radio-button-visible-wrap {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.mds-form__radio-button-visual {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: 0 0;
    border: 1px solid grey;
    border-radius: 50%;
    color: #5e5e5e;
    cursor: pointer;
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 6px 0 0;
    position: relative;
    top: 1px;
    width: 16px;
}

.mds-form__radio-button-visual:before {
    background: #0077cf;
    border-radius: 50%;
    content: '';
    display: block;
    height: 12px;
    left: 50%;
    margin-left: -6px;
    margin-top: -6px;
    opacity: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: scale(.4);
    transform: scale(.4);
    -webkit-transition: .1s linear;
    transition: .1s linear;
    width: 12px;
}

.modal-content .mds-data-table__cell {
  border: none
}

.modal-content tr td:first-child {
  padding-top: 14px
}

.pad-14 {
  padding-top: 14px;
}
.mds-form__radio-button-input:checked+.mds-form__radio-button-visible-wrap .mds-form__radio-button-visual:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.mds-form__radio-button-input:checked+.mds-form__radio-button-visible-wrap .mds-form__radio-button-text {
    color: #1e1e1e;
}

.error .multiselect__tags{
  border: 1px solid red !important;
}

.ratios-table {
  display: flex !important;
  width: 100% !important;
}
.ratios-table > div:nth-child(2n - 1){
      margin-top: 10px !important;
      width: 30% !important;
  }

  .ratios-table > div:nth-child(2n){
      margin-top: 10px !important;
      width: 24% !important;
  }

  .search {
    text-align: center !important;
    display: block !important;
  }

  .ratios-container {
    display: block !important;
  }

  .fa-exclamation-triangle:before {
    content: "\f071";
    color: red;
  }

.mds-alert--error .fa-exclamation-triangle:before {
    content: "\f071";
    color: white;
  }

#modal-table {
  width: 88%
}

#modal-table td:first-child {
  width: 50%;
}

#modal-table td:nth-child(2) {
    width: 45%;
}

.mds-form__input {
    font-size: 16px;
    padding: 5px 8px 4px;
    background-clip: padding-box;
    background-color: #e5e5e5 !important;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: inset 0 -1px 0 0 grey;
    box-shadow: inset 0 -1px 0 0 grey;
    color: #1e1e1e;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    height: 29px;
    text-align: left;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    width: 100%;
}

.modal-alert {
  position: relative;
  left: 16%;
  width: 66%;
}

.mds-alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
}

.mds-alert__cell-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 16px;
}

.mds-alert--error .mds-alert__cell-icon {
    background-color: red;
    border-color: red;
}

.mds-alert--success .mds-alert__cell-icon {
    background-color: #00af41;
    border-color: #00af41;
}


.mds-alert__cell-body {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-bottom-right-radius: 6px;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-top-right-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 16px;
}

.mds-alert--error.mds-alert--tinted .mds-alert__cell-body, .mds-alert--error.mds-alert--tinted .mds-alert__cell-dismiss {
    background-color: #ffe5e5;
}

.mds-alert--success.mds-alert--tinted .mds-alert__cell-body, .mds-alert--success.mds-alert--tinted .mds-alert__cell-dismiss {
    background-color: #e5f7eb;
}

.mds-alert__message {
    line-height: 1.25;
    border-bottom: 1px solid transparent;
    color: #1e1e1e;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin: 0 0 16px;
}

.mds-alert__message::after, .mds-alert__message::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
}

.mds-alert--success.mds-alert__variation {
    background-color: #00af41;
    border-color: #00af41;
}

.mds-alert.mds-alert--success.mds-alert--tinted .mds-alert__body {
    background-color: #e5f7eb;
}

.alert-close {
    top: 0;
    right: 2%;
    position: absolute;
}

.fa-check::before {
  color: white;
}

.multiselect__option--group {
    background: #ededed;
    color: #35495e;
}

.multiselect__option--group {
  background-color: #dddddd !important;
  font-weight: bold;
}

.multiselect__option--group.multiselect__option--highlight {
    background: #35495e !important;
    color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a !important;
    color: #fff;
}

.mds-empty-state {
    align-items: center;
    background-color: transparent;
    color: #1e1e1e;
    max-width: 320px;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.mds-empty-state .mds-empty-state__title {
    font-family: "Univers",HelveticaNeue,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-size: 23px;
    font-weight: 300;
    line-height: 27px;
    display: block;
    margin-bottom: 32px;
}

.mds-empty-state .mds-empty-state__message {
    font-family: "Univers",HelveticaNeue,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    margin-top: 16px;
}

.mds-empty-state .mds-empty-state__actions {
    margin-top: 20px;
}

.mds-empty-state .mds-empty-state__icon {
    display: inline-block;
    height: 85px;
    margin-bottom: 20px;
    width: 85px;
}

.restricted-access-container {
    padding-top: 10%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.filter-icon {
  margin-top: -6px;
}

</style>
