<template>
    <div class="mds-dialog-wrapper">
        <div class="mds-overlay">
        </div>
        <div role="dialog" class="mds-dialog mds-dialog--active mds-dialog--width-500px" style="text-align: left">
            <div id="mds-dialog-message" class="mds-dialog__message" tabindex="10">
                Are you sure you want to choose {{ numberDenomination }} as Number denomination?
            </div>
            <div class="mds-dialog__action-buttons">
                <div class="mds-dialog__cancel-resolve-buttons-holder">
                    <button class="mds-button mds-dialog__cancel-button" type="button" 
                        style="margin-right: 10px"
                        role="button" @click="cancel">
                        Cancel
                    </button>
                    <button class="proceedBtn" 
                        type="button" @click="confirm">
                        Yes, Proceed
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Confirmation',
    components: {
        
    },
    props: ['cancel', 'confirm', 'numberDenomination']
}
</script>
<style>

</style>
    