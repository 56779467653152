<template>
  <div style="margin-left: 15%">
    <div style="display: table;">
        <div class="uploadHeader"> 
          <span>Keywords, weightage config file</span>
          <span style="vertical-align: middle; margin-left: 8px;">
            <img src="icons8-download-24.png" style="width: 16px; height: 16px" @click="downloadConfig('config/text_weight_config_1.json')"/>
          </span>
        </div>
        <div style="padding-bottom: 5px">  {{ uploadStdConfig ? uploadStdConfig.name : ''}} </div>
        <input id="uploadStdCfg" type="file" hidden @change="showSelectedFile('fileNameSelectedStdCfg', 'uploadStdCfg')">
        <button id="browseBtnStdCfg" class="browseBtn" @click="browse('fileNameSelectedStdCfg', 'uploadStdCfg')">Browse</button>
        <button id="uploadBtnStdCfg" class="uploadBtn" @click="upload('uploadStdCfg', 'browseBtnStdCfg', 'std-config', 'fileNameSelectedStdCfg')">Upload</button>
      </div>
    <br>
    <br>
      <div style="display: table">
        <div class="uploadHeader"> 
          <span>Lineitem mappings config file</span>
          <span style="vertical-align: middle; margin-left: 8px;">
            <img src="icons8-download-24.png" style="width: 16px; height: 16px" @click="downloadConfig('config/Morningstar-Mapping-Header.json')"/>
          </span>
        </div>
        <div style="padding-bottom: 5px">{{ uploadHeaderConfig ? uploadHeaderConfig.name : ''}}</div>
        <input id="uploadHeaderCfg" type="file" hidden @change="showSelectedFile('fileNameSelectedHdrCfg', 'uploadHeaderCfg')">
        <button id="browseBtnHdrCfg" class="browseBtn" @click="browse('fileNameSelectedHdrCfg', 'uploadHeaderCfg')">Browse</button>
        <button id="uploadBtnHdrCfg" class="uploadBtn" @click="upload('uploadHeaderCfg', 'browseBtnHdrCfg', 'header-config', 'fileNameSelectedHdrCfg')">Upload</button>
      </div>
    </div>
</template>
<script>

import { browse, downloadFile } from '@/utils';
import { uploadConfigFile } from '@/apis/reports';
export default {
  name: 'Config',
  data() {
      return {
           uploadStdConfig: null,
           uploadHeaderConfig: null 
      };
  },
  props: ['showErrorAlert', 'showSuccessAlert', 'showLoaderToggle', 'changeActiveTab'],
  mounted() {
    this.changeActiveTab({
      name: 'Config',
      routerLink: '/config'
    });
  },
  methods: {
     browse(selectedFileElementId, uploadBtnElementId) {
         if (uploadBtnElementId === 'uploadStdCfg') {
            this.uploadStdConfig =  null;
        }
        else if (uploadBtnElementId === 'uploadHeaderCfg') {
            this.uploadHeaderConfig =  null;
        }
        browse(selectedFileElementId, uploadBtnElementId);
     },
     showSelectedFile(selectedFileElementId, uploadBtnElementId) {
        let file = document.getElementById(uploadBtnElementId).files[0];
        if (uploadBtnElementId === 'uploadStdCfg') {
            this.uploadStdConfig =  file ? file : null;
        }
        else if (uploadBtnElementId === 'uploadHeaderCfg') {
            this.uploadHeaderConfig =  file ? file : null;
        }
     },
    downloadConfig(fileName) {
      downloadFile(fileName);
    },
    validateInput() {
      return this.companyName && this.reportType;
    }, 
    checkJSON(file) {
      return file ? file.type === 'application/json' : false;
    },
    upload(uploadBtnElementId) {
      if ((uploadBtnElementId === 'uploadStdCfg' && this.uploadStdConfig === null) ||
        (uploadBtnElementId === 'uploadHeaderCfg' && this.uploadHeaderConfig === null)) {
           this.showErrorAlert('Please choose a file before uploading.')
      } else if (!this.checkJSON(this.uploadStdConfig) && !this.checkJSON(this.uploadHeaderConfig)) {
        this.showErrorAlert('Please choose a JSON file to upload.'); 
      } else {
        this.showLoaderToggle(true);
        if (uploadBtnElementId === 'uploadStdCfg') {
          uploadConfigFile('config' + '/' + this.uploadStdConfig.name, this.uploadStdConfig)
            .then(() => this.onUploadSuccess());
        } else if (uploadBtnElementId === 'uploadHeaderCfg') {
          uploadConfigFile('config' + '/' + this.uploadHeaderConfig.name, this.uploadHeaderConfig)
            .then(() => this.onUploadSuccess());
        }
      }  
    },
    onUploadSuccess() {
      this.showLoaderToggle(false);
      this.showSuccessAlert('Config file uploaded succesfully');
      this.uploadStdConfig = null;
      this.uploadHeaderConfig = null;
    }
  }
  
}


</script>
<style>
  
</style>