import axios from 'axios';

export const UIM_AUTH_PATH = '/sso/json/msusers/authenticate';
const urlWithProtocol = new RegExp('^https?://');
import { getAPIHost } from '@/apis/apihost'; 
/**
 * All user  authentication / security api and methods goes here
 * More on UIM : https://mswiki.morningstar.com/display/PDS/UIM%3A+Access+Management+%28OpenAM%29+APIs
 */

// TODO : needs to go into some constant file
export const UIM_MS_SSO_KEY = 'ms-sso';
export const LOGIN_COOKIE_EXPIRATION = '30d';
export const LOGIN_URL = process.env.VUE_APP_LOGIN_URL;
export const LOGIN_INFO_MSG_TOKEN_UNAUTHORIZED =
  'Your session has expired. Please sign in to pick up where you left off.';

export function getUimHost() {
  return getUimUrl(process.env);
}

export function getUimUrl(env) {
  if (env.VUE_APP_UIM_HOST) {
    let host = env.VUE_APP_UIM_HOST;
    if (!urlWithProtocol.test(host)) {
      host = 'https://' + host;
    }
    return host;
  } else {
    throw new Error('Missing environment variable: VUE_APP_UIM_HOST');
  }
}

/**
 * Validate UIM cookie
 */
 export function validateUimCookie() {
  const url = `${getAPIHost()}/public/sso/validate`;
  return axios
    .get(url)
    .then(resp => resp.data.valid)
    .catch(err => {
      console.error('failed to authenticate sso token; expect more errors', err);
      // assume the best: token is valid.
      return true;
    });
}

/**
 * Logout
 */
 export function logoutUim() {
  const location = window.location;
  const logoutUrl = new URL(getUimHost() + '/sso/json/logout-browser');
  logoutUrl.searchParams.append(
    'returnTo',
    `${location.protocol}//${location.host}/`
  );
  location.href = logoutUrl;
}

let redirected = false;

/**
 * Redirect a user to the external Credit Login page.
 * Redirects using window.location.href and operates outside the scope of the Vue Router.
 * @param {URL} successUrl - A URL for the Credit Login page to redirect to upon success.
 */
export function redirectToCreditLogin(successUrl) {
  if (redirected) {
    return;
  }
  if (!successUrl) {
    throw new Error('successUrl is required.');
  }
  if (!(successUrl instanceof URL)) {
    throw new Error('successUrl should be an instance of URL.');
  }
  const loginUrl = new URL(LOGIN_URL);
  loginUrl.searchParams.append('targetUrl', successUrl);
  window.location.href = loginUrl;
  redirected = true;
}
