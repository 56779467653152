import Vue from 'vue';

export function fileName(val) {
    if (val && val.lastIndexOf('_') !== -1) 
        return val.substring(0, val.lastIndexOf('_')) +  
            val.substring(val.lastIndexOf('.'), val.length);
    else 
        return val;
}

Vue.filter('fileName', fileName);