<template>
    <div v-if="showSelectYears">
        <span style="display: flex; font-weight: bold"> Step 2: Select year(s) </span>
        <div id="year-table">
        <div> 
            <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
            <span style="padding-left: 5px;"> Please select minimum 1 year and maximum 2 years </span>
            </div>
        <div>
                    <div  class="label-div">
                    <label> Year </label>
                    </div>
                    <div>
                    <Multiselect v-model="yearsSelected" label="name" track-by="code" 
                        :options="years" :multiple="true"
                        :closeOnSelect="false"
                        @input="selectYear"
                        placeholder="Select a year"
                        selectLabel="Select year"
                        deselectLabel="Remove year"
                        :class="{ 'error': showYearsError}">
                    </Multiselect>
                    <div v-if="showYearsError">
                        <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                        <span style="padding-left: 5px; color: red"> Please select atleast 1 year </span>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
export default {
    name: 'SelectYears',
    props: ['showSelectYears', 'years', 'addSelectedYear', 'showYearsError'],
    data() {
        
        return {
            yearsSelected: []
        };
        
    },
    
    computed: {
        
    
    },
    watch: {
        showSelectYears: {
            handler: function () {
                if (!this.showSelectYears) {
                    this.yearsSelected = [];
                }   
            }
        }
    },
    mounted() {
        
        
    },
    methods: {
        selectYear() {
            this.addSelectedYear(this.yearsSelected)
        }
    },
    components: {
        Multiselect
    }
}
</script>