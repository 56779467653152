import axios from 'axios';
import { redirectToCreditLogin } from '@/apis/userauthentication';

axios.defaults.withCredentials = true;
export function axiosInit() {
  axios.interceptors.request.use(
    function(config) {
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(function(response) {
    return response;
  }, handleResponseError);
}

function handleResponseError(error) {
  if (error.response && error.response.status === 401) {
    const successUrl = new URL(window.location.href);
    if (error.response.data && error.response.data.message) {
      redirectToCreditLogin(successUrl, error.response.data.message);
    }
    redirectToCreditLogin(successUrl);
  }
  return Promise.reject(error);
}
