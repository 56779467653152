<template>
    <div id="in-progress-reports">
        <div style="float: right">
            <a  href="javascript:void(0)" @click="getInprogressCompanyReports" >Refresh </a>
        </div>
      <table id="in-progress-reports" class="mds-data-table">
          <thead class="mds-data-table__header">
          <tr>
            <th width="8%" class="mds-data-table__header-cell">
                <div>
                    <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Company Name </span>
                    <span class="filter-icon"> <img @click="filters.company.open = !filters.company.open" src="filter-512.png" width="20" height="20"/> </span>
                </div>
                <div>
                    <input type="text" v-if="filters.company.open" v-model="filters.company.value"/>
                </div>
            </th>
            <th width="5%" class="mds-data-table__header-cell">
                <div>
                    <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Period </span>
                    <span class="filter-icon"> <img @click="filters.period.open = !filters.period.open" src="filter-512.png" width="20" height="20"/> </span>
                </div>
                <div>
                    <input type="text" v-if="filters.period.open" v-model="filters.period.value"/>  
                </div>          
            </th>
            <th width="28%" class="mds-data-table__header-cell">
                <div>
                    <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Original Company Report </span>
                    <span class="filter-icon"> <img @click="filters.originalCompanyReport.open = !filters.originalCompanyReport.open" src="filter-512.png" width="20" height="20"/> </span>
                </div>
                <div>
                    <input type="text" v-if="filters.originalCompanyReport.open" v-model="filters.originalCompanyReport.value"/>
                </div>
            </th>
            <th width="28%" class="mds-data-table__header-cell">
                <div>
                    <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Initial Transformation </span>
                    <span class="filter-icon"> <img @click="filters.initialTransformation.open = !filters.initialTransformation.open" src="filter-512.png" width="20" height="20"/> </span>
                </div>
                <div>
                    <input type="text"  v-if="filters.initialTransformation.open" v-model="filters.initialTransformation.value"/>            
                </div>
            </th>
            <th width="25%" class="mds-data-table__header-cell">
                <div>
                    <span class="mds-data-table__cell--left mds-data-table__header-cell-inner"> Forcasting Tool </span>
                    <span class="filter-icon"> <img @click="filters.forcastingTool.open = !filters.forcastingTool.open" src="filter-512.png" width="20" height="20"/> </span>
                </div>
                <div>
                    <input type="text"  v-if="filters.forcastingTool.open" v-model="filters.forcastingTool.value"/>
                </div>
            </th>
          </tr>
          </thead>
          <tbody class="mds-data-table__body">
          <tr class="mds-data-table__row" :key="i" v-for="(report, i) in displayedReports">
            <td class="mds-data-table__cell" >
               <span> {{ report.company }}  </span>
            </td>
            <td class="mds-data-table__cell" > 
               <span> {{ report.period }}  </span>
            </td>
            <td class="mds-data-table__cell" >
               <span style="width: 85%; display: block; float: left; padding-right: 15px"
                    :title="report.originalCompanyReport"> 
                    {{ report.originalCompanyReport | fileName }}  
                </span>
               <span @click="downloadReport('input', report.originalCompanyReport)"> <i style="cursor: pointer" class="fa fa-file-pdf-o fa-lg" aria-hidden="true"></i> </span>
            </td>
            <td class="mds-data-table__cell">
                <span v-if="report.initialTransformation">
                    <span style="width: 85%; display: block; float: left; padding-right: 15px"
                        :title="report.initialTransformation"> 
                        {{ report.initialTransformation | fileName }} 
                        </span>
                    <span style="margin-right: 5px; cursor: pointer" @click="downloadReport('process', report.initialTransformation)"> 
                        <i class="fa fa-file-excel-o fa-lg" aria-hidden="true" style="vertical-align: -3px;">
                        </i> </span>
                    <span v-if="report.period === 'Quaterly'" @click="browse()"> 
                        <input id="uploadQuaterly" type="file" hidden @change="upload(report.reportId, report.companyId)">
                        <i style="cursor: pointer" class="fa fa-lg fa-upload" aria-hidden="true"></i> 
                    </span>
                </span>
                <span v-if="report.initialTransformationInProgress"> 
                    <span> <i class="fa fa-spin fa-spinner" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px;"> Generating file... </span>
                </span>
                <span v-if="report.initialTransformationError"> 
                    <span> <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px; color: red"> Failed to generate </span>
                </span>

            </td>
            <td class="mds-data-table__cell" >
              <span v-if="report.forcastingInProgress"> 
                    <span> <i class="fa fa-spin fa-spinner" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px;"> Generating file... </span>
              </span>
              <span v-if="report.forcastingError"> 
                    <span> <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px; color: red"> Failed to generate </span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
    </div>
</template>

<script>
import { pickBy, filter, mapValues, keys } from 'lodash';
import { getInProgressReports, uploadQuaterlyTemplate, uploadCompanyReport } from '@/apis/reports';
import { downloadFile } from '@/utils';

export default {
    name: 'InProgressReports',
    data() {
        return {
            reports: [],
            refreshTimeout: null,
            fileSelected: '',
            fileNameSelected: '',
            filters: {
               'company': {
                   value: '',
                   open: false
                },
                'period': {
                   value: '',
                   open: false
                },
                'originalCompanyReport': {
                   value: '',
                   open: false
                },
                'initialTransformation': {
                   value: '',
                   open: false
                },
                'forcastingTool': {
                   value: '',
                   open: false
                }
            }
        };
    },
    props: ['showLoaderToggle', 'toggleRestrictedAccess', 'showErrorAlert'],
    computed: {
        anyFilterOpen() { 
            return this.filters.company.open || this.filters.period.open || this.filters.originalCompanyReport.open ||
                this.filters.initialTransformation.open || this.filters.forcastingTool.open;
        },
        displayedReports() {
            let definedFilters = pickBy(this.filters, filter => filter.value !== '');
            let filterKeyValues = mapValues(definedFilters, 'value');
            let filterKeys = keys(filterKeyValues);
            return filter(this.reports, report => {
               let filtersUnmatched = filter(filterKeys , key => {
                   return report[key].toLowerCase().indexOf(filterKeyValues[key].toLowerCase()) === -1;
               });
               return filtersUnmatched.length === 0;

            } );
        }
    },
    mounted() {
      this.getInprogressCompanyReports();
    },
    methods: {
      browse() {
          this.fileSelected = {};
          this.fileNameSelected = '';
          document.getElementById('uploadQuaterly').value = null;
          document.getElementById('uploadQuaterly').click();
      }, 
      upload(transactionId, companyId) {
           this.fileSelected =  document.getElementById('uploadQuaterly').files 
                                ? document.getElementById('uploadQuaterly').files[0] 
                                : {};
           this.fileNameSelected = this.fileSelected.name || '';
           if (this.fileSelected.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              this.showErrorAlert('Please choose an XLSX file to upload.'); 
              return;
           }
           this.showLoaderToggle(true); 
           uploadQuaterlyTemplate(transactionId, companyId, 'process/' + this.fileNameSelected)
              .then(res => uploadCompanyReport(res.data['upload_url'], this.fileSelected))
              .then(() => this.getInprogressCompanyReports())
              .catch(err => this.onUploadError(err));

      },
      onUploadError(err) {
        this.showLoaderToggle(false);
        this.showErrorAlert(err.response.data.message || err.message);
      },
      downloadReport(fileType, fileName) {
        downloadFile(fileType + '/' + fileName)
            .catch(err => {
                console.log(JSON.stringify(err.response));
                this.showErrorAlert(err.response.data.message || err.message);
            });
      },
        getInprogressCompanyReports() {
            this.showLoaderToggle(true);
            getInProgressReports()
                .then(res => {
                    let inProgressReports = res.data;
                    this.reports = [];
                    inProgressReports.forEach(report => {
                        let reportUI = {};
                        reportUI.companyId = report.companyId;
                        reportUI.reportId = report.transactionId;
                        reportUI.company = report.companyName;
                        reportUI.originalCompanyReport = report.inputFile;
                        reportUI.period = report.reportType;
                        if (report.transformFile) {
                          reportUI.initialTransformation = report.transformFile;
                        } else if (!report.transformFile && !report.error_id){
                          reportUI.initialTransformationInProgress = true;
                        } else if (!report.transformFile && report.error_id){
                          reportUI.initialTransformationError = true;
                        }

                        if (report.transformFile && !report.error_id) {
                           reportUI.forcastingInProgress = true;
                        } else if (report.transformFile && report.error_id) {
                           reportUI.forcastingError = true;
                        }
                        this.reports.push(reportUI);
                    });
                    this.showLoaderToggle(false);
                })
                .catch(err => {
                    this.showLoaderToggle(false);
                    console.log(JSON.stringify(err.response));
                    if (err.response.status === 403) {
                        this.toggleRestrictedAccess(true);
                    } else {
                        this.showErrorAlert(err.response.data.message || err.message);
                    }
                });

        }
    }

}
</script>
<style>
.filter-row td {
    text-align: center;
}
.forcasting-tool button {
    height: 36px;
}

#in-progress-reports td span {
  overflow: hidden;
  text-overflow: ellipsis;
}

#in-progress-reports th div {
   display: flex;
}
</style>