import Vue from 'vue';
import App from './App.vue';
import Retrieval from './components/Retrieval.vue';
import Config from './components/Config.vue';
import Home from './components/Home.vue';
import PageNotFound from './components/PageNotFound';
import { axiosInit } from '@/axios-interceptor';
import {
  validateUimCookie,
  redirectToCreditLogin
} from '@/apis/userauthentication';

import VueRouter from 'vue-router'

Vue.use(VueRouter)

Vue.config.productionTip = false
const authCacheMillis = 60000;
let authCheckTime = 0;

let router = new VueRouter({
  mode: 'history',
  routes: [
    // dynamic segments start with a colon
    { path: '/', component: App },
    { path: '/home', component: Home },
    { path: '/reporting-tool', component: Retrieval },
    { path: '/config', component: Config },
    {
      path: '*',
      component: PageNotFound,
      meta: {
        requiresAuth: false
      }
    }
  ]
});

export function doesRouteRequireAuth(matched) {
  // we assume all routes require auth unless explicity set to false
  return !matched.some(
    route => route.meta && route.meta.requiresAuth === false
  );
}

router.beforeEach((to, from, next) => {
  if (Date.now() - authCheckTime <= authCacheMillis) {
    next();
  } else {
    const routeRequiresAuth = doesRouteRequireAuth(to.matched);
    if (!routeRequiresAuth) {
      next();
    } else {
      const successUrl = new URL(window.location.href);
      validateUimCookie().then(isValid => {
        if (isValid) {
          authCheckTime = Date.now();
          next();
        } else {
          redirectToCreditLogin(successUrl);
          next(false);
        }
      });
    }
  }
});

axiosInit();

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
