<template>
    <div id="root" class="retrieval-container">

  <Tabs>
    <Tab name="Complete" :selected="true">
      <CompleteReports :showLoaderToggle="showLoaderToggle" :toggleRestrictedAccess="toggleRestrictedAccess"/>
    </Tab>
    <Tab name="In Progress">
      <InProgressReports 
        :showLoaderToggle="showLoaderToggle" 
        :toggleRestrictedAccess="toggleRestrictedAccess"
        :showErrorAlert="showErrorAlert" 
      />
    </Tab>

    <Tab name="Input Company Report / Upload Forecasting Tool">
      <InputCompanyReport
        :onInputReportSuccess="onInputReportSuccess"
        :onUpdateReportSuccess="onUpdateReportSuccess"
        :showSuccessAlert="showSuccessAlert"
        :showErrorAlert="showErrorAlert" 
        :showLoaderToggle="showLoaderToggle"
        :companyInfo="companyInfo"
        :getCompanies="getCompanies"/>
    </Tab>

  </Tabs>

  </div>
</template>
<script>
import Tabs from './Tabs.vue';
import Tab from './Tab.vue';
import CompleteReports from './CompleteReports';
import InProgressReports from './InProgressReports';
import InputCompanyReport from './InputCompanyReport';
export default {
  name: 'Home',
  data() {
    return  {
      selectedTab: 'Completed'
    };
  },
  props: ['showLoaderToggle', 'changeActiveTab', 'showSuccessAlert', 'showErrorAlert' ,'companyInfo', 'toggleRestrictedAccess', 'getCompanies'],
  components: {
      Tabs,
      Tab,
      CompleteReports,
      InProgressReports,
      InputCompanyReport
  },
  mounted() {
    this.changeActiveTab({
      name: 'Home',
      routerLink: '/home'
    });
  },
  methods: {
    onInputReportSuccess() {
      this.$children[0].selectTab('In Progress');
      this.$children[0].tabs.find(tab => tab.name === 'In Progress').$children[0].getInprogressCompanyReports();
      this.showSuccessAlert('File uploaded successfully. Please refresh after few seconds to check the transformed files ' + 
        'and standardized statements');
    },
    onUpdateReportSuccess() {
      this.showSuccessAlert('File updated successfully');
    }
  }  
}
</script>