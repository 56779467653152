<template>
  <!-- Modal content -->
  <div class="modal-content">
    <table id="modal-table" class="mds-data-table">
      <tbody class="mds-data-table__body">
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Company 
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell">
              <Multiselect label="companyName" track-by="companyName"
                id="companyName" 
                :options="companies"
                :taggable="true"
                v-model="company"
                placeholder="Select a company"
                selectLabel="Select company"
                deselectLabel="Remove company"
                tag-placeholder="Add a new Company"
                @tag="addCompany"
              />
              <div v-if="showCompanyError">
                  <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px; color: red"> Please select a company </span>
                </div>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Methodology 
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell">
              <Multiselect label="sectorName" track-by="sectorName"
                id="methodologyName" 
                :options="methodologies"
                v-model="methodology"
                placeholder="Select a methodology"
                selectLabel="Select methodology"
                deselectLabel="Remove methodology"
                :disabled="disableMethodology"
              />
              <div v-if="showMethodologyError">
                  <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px; color: red"> Please select a methodology </span>
                </div>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Report Type
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell pad-14">
              <label v-for="report in reportTypes" :key="report.reportId" class="container">
                  {{ report.reportName }}
                <input v-model="reportType" type="radio" name="radio" :value="report.reportId">
                <span class="checkmark"></span>
              </label>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Override
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell pad-14">
              <label class="container">
                  Yes
                <input v-model="override" type="radio" name="override-radio" value="yes">
                <span class="checkmark"></span>
              </label>
               <label class="container">
                  No
                <input v-model="override" type="radio" name="override-radio" value="no">
                <span class="checkmark"></span>
              </label>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
             <label class="mds-form__label"> 
                Balance Sheet Page Number
             </label>  
          </td>
          <td class="mds-data-table__cell">
              <input id="balSheetPgNo" type="text" v-model="balSheetPageNo"
              class="mds-form__input" placeholder="e.g: 1 / 1,2,3" autocomplete="off"/>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
            <label class="mds-form__label"> 
                Income Statement Page Number
            </label>
          </td>
          <td class="mds-data-table__cell">
              <input id="incStmtPgNo"  type="text" v-model="incomeStmtPgNo"
              class="mds-form__input" placeholder="e.g: 1 / 1,2,3" autocomplete="off"/>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
            <label class="mds-form__label"> 
                Cash Flow Statement Page Number
            </label>
          </td>
          <td class="mds-data-table__cell">
              <input id="cashFlowPgNo"  type="text" v-model="cashFlowStmtPgNo" 
              class="mds-form__input" placeholder="e.g: 1 / 1,2,3" autocomplete="off"/>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell" colspan="2">
              <input id="upload" type="file" hidden @change="showSelectedFile('fileNameSelected', 'upload')">
              <button id="browseBtn" style="width: auto" class="browseBtn" @click="browse()">Upload</button>
              <span id="fileNameSelected" class="fileNameSelected" :title="fileNameSelected"> 
                  {{ fileNameSelected }}
              </span>
              <button id="proceed" style="width: auto" class="proceedBtn" 
                  @click="proceed()">Proceed</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { REPORT_TYPES } from '@/constants';
import Multiselect from 'vue-multiselect';
import { getUploadReportURL, uploadCompanyReport } from '@/apis/reports';

export default {
  name: 'InputCompanyReportDetails',
  data() {
    return {
      fileSelected: {},
      fileNameSelected: '',
      company: null,
      methodology: null,
      override: 'no',
      reportType: this.reportTypes.find(rep => rep.reportName === REPORT_TYPES.ANNUAL).reportId,
      balSheetPageNo: undefined,
      incomeStmtPgNo: undefined,
      cashFlowStmtPgNo: undefined,
      modalErrorAlertVisible: false,
      modalErrAlertMessage: '',
      showCompanyError: false,
      showMethodologyError: false
    }
  },
  props: ['companies', 'methodologies', 'reportTypes', 'showLoaderToggle', 
    'onInputReportSuccess', 'showSuccessAlert', 'showErrorAlert', 'getCompanies'],
  mounted() {
    var modal = document.getElementById("myModal");  
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        this.showModal = false;
      }
    }
  },
  components: {
    Multiselect
  },
  computed: {
    disableMethodology() {
      if (this.company) {
        let companyFound = this.companies.find(company => company.companyName === this.company.companyName);
        return companyFound && companyFound.sectorId ? true: false;
      } else {
        return false;
      }
    }
  },
  watch: {
    company() {
      if (this.company) {
        let companyFound = this.companies.find(company => company.companyName === this.company.companyName);
        if (companyFound) {
          this.methodology = this.methodologies.find(methodology => methodology.sectorId === companyFound.sectorId);
        } else {
          this.methodology = null;
        }
      } else {
        this.methodology = null;
      }
    }
  },
  methods: {
    browse() {
      this.fileSelected = {};
      this.fileNameSelected = '';
      document.getElementById('upload').value = null;
      document.getElementById('upload').click();
    },
    addCompany (newCompany) {
      const company = {
        companyId: 0,
        companyName: newCompany
      }
      this.companies.push(company);
      this.company = company;
      this.company.sectorId = null;
      this.methodology = null;
    },
    showSelectedFile() {  
      this.fileSelected =  document.getElementById('upload').files 
                                ? document.getElementById('upload').files[0] 
                                : {};
      this.fileNameSelected = this.fileSelected.name || '';
    },
    clearModalData() {
      document.getElementById('upload').value = null;
      this.fileSelected = {};
      this.fileNameSelected = '';
      this.company = null;
      this.methodology = null;
      this.override = 'no';
      this.reportType = this.reportTypes.find(rep => rep.reportName === REPORT_TYPES.ANNUAL).reportId;
      this.balSheetPageNo = undefined;
      this.incomeStmtPgNo = undefined;
      this.cashFlowStmtPgNo = undefined;
    },
    closeModal() {
      this.clearModalData();
      this.$emit('close-modal', 'input-details') 
    },
    pageNumbersValid() {
      let allPageNos = [];
      if (this.balSheetPageNo) {
        allPageNos.push(...this.balSheetPageNo.split(','));
      }
      if (this.incomeStmtPgNo) {
        allPageNos.push(...this.incomeStmtPgNo.split(','));
      }
      if (this.cashFlowStmtPgNo) {
        allPageNos.push(...this.cashFlowStmtPgNo.split(','));
      }
      return allPageNos.find(pgNo => isNaN(pgNo)) === undefined;
    },
    proceed() {
      if (!this.validateInput()) {
        this.showErrorAlert('Please input required fields before proceeding');
      } else if(!this.pageNumbersValid()) {
        this.showErrorAlert('Page numbers are invalid. Please enter numbers only seperated by commas.'); 
      } else if (!this.fileNameSelected) {
        this.showErrorAlert('Please choose a file to upload first.'); 
      } 
      else if (this.fileSelected.type !== 'application/pdf') {
        this.showErrorAlert('Please choose a PDF file to upload.'); 
      } else {
        this.modalErrorAlertVisible = false;
        let payload = {
          uploaded_file: 'input/' + this.fileNameSelected,
          company_id: this.company.companyId,
          company_name: this.company.companyName,
          sector_id: this.methodology.sectorId,
          sector_name: this.methodology.sectorName,
          report_type_id: this.reportType,
          is_override: this.override,
          bs_page_no: this.balSheetPageNo,
          is_page_no: this.incomeStmtPgNo,
          cf_page_no: this.cashFlowStmtPgNo  
        };

        this.showLoaderToggle(true);

        getUploadReportURL(payload)
          .then(res => uploadCompanyReport(res.data['upload_url'], this.fileSelected))
          .then(() => this.getCompanies())
          .then(() => this.onUploadSuccess())
          .catch(err => this.onUploadError(err))
      } 
    },
    onUploadSuccess() {
      this.showLoaderToggle(false);
      this.clearModalData();
      this.onInputReportSuccess();
    },
    onUploadError(err) {
      this.showLoaderToggle(false);
      console.log(JSON.stringify(err.response));
      this.showErrorAlert(err.response.data.message || err.message);
    },
    clearErrors() {
      this.showCompanyError = false;
      this.showMethodologyError = false;
    },
    validateInput() {
      let isAnyError = false;
      this.clearErrors();
      if (!this.company) {
        this.showCompanyError = true;
        isAnyError = true;
      }
      if (!this.methodology) {
        this.showMethodologyError = true;
        isAnyError = true;
      }
      return !isAnyError;
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

</style>