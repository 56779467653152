<template>
  <!-- Modal content -->
  <div class="modal-content">
    <table id="modal-table" class="mds-data-table">
      <tbody class="mds-data-table__body">
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
             <label class="mds-form__label"> 
                Company
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
             </label>
          </td>
          <td class="mds-data-table__cell">
              <Multiselect label="companyName" track-by="companyName"
                id="companyName" 
                :options="companies"
                :taggable="true"
                v-model="company"
                placeholder="Select a company"
                selectLabel="Select company"
                deselectLabel="Remove company"
                tag-placeholder="Add a new Company"
                @tag="addCompany"
              />
              <div v-if="showCompanyError">
                  <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px; color: red"> Please select a company </span>
                </div>
          </td>
        </tr>
         <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Methodology
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell">
             <Multiselect label="sectorName" track-by="sectorName"
                id="methodologyName" 
                :options="methodologies"
                v-model="methodology"
                placeholder="Select a methodology"
                selectLabel="Select methodology"
                deselectLabel="Remove methodology"
                :disabled="disableMethodology"
              />
              <div v-if="showMethodologyError">
                  <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px; color: red"> Please select a methodology </span>
                </div>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Currency
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
         <td class="mds-data-table__cell">
             <Multiselect label="name" track-by="code"
                id="currencySelect" 
                :options="currencies"
                v-model="currency"
                placeholder="Select a currency"
                selectLabel="Select currency"
                deselectLabel="Remove currency"
                :disabled="company && company.currency !== undefined && company.currency !== ''"
              />
              <div v-if="showCurrencyError">
                  <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px; color: red"> Please select a currency </span>
                </div>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Is Databasing Needed?
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell pad-14">
              <label class="container">Yes
                <input v-model="isDatabasingNeeded" type="radio" name="isDatabasingNeeded" value="Yes">
                <span class="checkmark"></span>
              </label>
              <label class="container">No
                <input v-model="isDatabasingNeeded" type="radio" name="isDatabasingNeeded" value="No">
                <span class="checkmark"></span>
              </label>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell">
              <label class="mds-form__label"> 
                Number denomination in Forecasting tool
                <abbr  title="This field is required." 
                  aria-label="This field is required." class="mds-form__label-required-indicator">
                    *
                </abbr>
              </label>
          </td>
          <td class="mds-data-table__cell pad-14">
              <label class="container">Thousands
                <input v-model="numberDenomination" type="radio" name="numberDenomination" value="Thousands">
                <span class="checkmark"></span>
              </label>
              <label class="container">Millions
                <input v-model="numberDenomination" type="radio" name="numberDenomination" value="Millions">
                <span class="checkmark"></span>
              </label>
          </td>
        </tr>
        <tr class="mds-data-table__row">
          <td class="mds-data-table__cell" colspan="2">
              <input id="upload" type="file" hidden @change="showSelectedFile('fileNameSelected', 'upload')">
              <button id="browseBtn" style="width: auto" class="browseBtn" @click="browse()">Upload</button>
              <span id="fileNameSelected" class="fileNameSelected" :title="fileNameSelected"> 
                  {{ fileNameSelected }}
              </span>
              <button id="proceed" style="width: auto" class="proceedBtn" 
                  @click="proceed()">Proceed</button>
          </td>
        </tr>
      </tbody>
    </table>
    <Confirmation v-if="openNumericConfirm" 
      :cancel="cancel" 
      :confirm="confirm"
      :numberDenomination="numberDenomination"/>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { getUpdateReportURL , uploadCompanyReport, updateDatabase, updateDatabaseForcast} from '@/apis/reports';
import Confirmation from './Confirmation';

export default {
  name: 'UpdateCompanyReportDetails',
  data() {
    return {
      fileSelected: {},
      fileNameSelected: '',
      company: null,
      methodology: null,
      currency: null,
      modalErrorAlertVisible: false,
      modalErrAlertMessage: '',
      showCompanyError: false,
      showMethodologyError: false,
      showCurrencyError: false,
      isDatabasingNeeded: 'No',
      numberDenomination: 'Millions',
      openNumericConfirm: false,
      currencies: [ {
        name: 'Dollars',
        code: 'dollars'
      }, {
        name: 'Euros',
        code: 'euros'
      }, {
        name: 'Pounds',
        code: 'pounds'
      }

      ]
    }
  },
  components: {
    Multiselect,
    Confirmation
  },
  computed: {
    disableMethodology() {
      if (this.company) {
        let companyFound = this.companies.find(company => company.companyName === this.company.companyName);
        return companyFound && companyFound.sectorId ? true : false;
      } else {
        return false;
      }
    }
  },
  watch: {
    company() {
      if (this.company) {
        let companyFound = this.companies.find(company => company.companyName === this.company.companyName);
        if (companyFound) {
          this.methodology = this.methodologies.find(methodology => methodology.sectorId === companyFound.sectorId);
          this.currency = this.company.currency ? {
            code: this.company.currency,
            name: this.currencies.find(currency => this.company.currency === currency.code).name
          } : null;
        } else {
          this.methodology = null;
          this.currency = null;
        }
      } else {
        this.methodology = null;
        this.currency = null;
      }
    },
    companies() {
      let companyFound = this.companies.find(company => company.companyName === this.company.companyName);
      if  (companyFound) {
        this.company = companyFound;
      }
    }
  },
  props: ['companies', 'methodologies', 'showLoaderToggle', 'showErrorAlert', 'onUpdateReportSuccess', 'getCompanies'],
  mounted() {
    var modal = document.getElementById("myModal");  
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        this.showModal = false;
      }
    }
  },
  methods: {
    browse() {
      this.fileSelected = {};
      this.fileNameSelected = '';
      document.getElementById('upload').value = null;
      document.getElementById('upload').click();
    },
    showSelectedFile() {  
      this.fileSelected =  document.getElementById('upload').files 
                                ? document.getElementById('upload').files[0] 
                                : {};
      this.fileNameSelected = this.fileSelected.name || '';
    },
    clearModalData() {
      this.company = null;
      this.methodology = null;
      this.currency = null;
      this.isDatabasingNeeded = 'No';
      this.numberDenomination = 'Millions'
      document.getElementById('upload').value = null;
      this.fileSelected = {};
      this.fileNameSelected = '';
      this.companyName = '';
    },
    closeModal() {
      this.clearModalData();
      this.$emit('close-modal', 'update-details') 
    },
    addCompany (newCompany) {
      const company = {
        companyId: 0,
        companyName: newCompany
      }
      this.companies.push(company);
      this.company = company;
      this.company.sectorId = null;
      this.methodology = null;
    },
    cancel() {
      this.openNumericConfirm = false;
    },
    openConfirm() {
      this.openNumericConfirm = true;
    },
    proceed() {
      if (!this.validateInput()) {
        this.showErrorAlert('Please input required fields before proceeding');
      } else if (!this.fileNameSelected) {
        this.showErrorAlert('Please choose a file to upload first.'); 
      } else if (this.fileSelected.type !== 'application/vnd.ms-excel.sheet.macroEnabled.12') {
        this.showErrorAlert('Please choose an XSLM file to upload.'); 
      } else {
        this.openConfirm();
      }
    },
    confirm() {
      this.modalErrorAlertVisible = false;
      this.openNumericConfirm = false;
      let payload = {
        company_id: this.company.companyId,
        company_name: this.company.companyName,
        sector_id: this.methodology.sectorId,
        sector_name: this.methodology.sectorName,
        databasing: this.isDatabasingNeeded === 'Yes' ? true : false,
        currency_denomination: this.numberDenomination
      };

      if (this.currency) {
        payload['currency'] = this.currency.code;
      }

      this.showLoaderToggle(true);
      let uploadedFileName = null;

      getUpdateReportURL('output/' + this.fileNameSelected)
        .then(res => {
          uploadedFileName = res.data['uploaded_file'];
          return uploadCompanyReport(res.data['upload_url'], this.fileSelected);
        })
        .then(() => {
          payload['uploaded_file'] = uploadedFileName;
          return updateDatabase(payload);
        })
        .then(() => {
          return updateDatabaseForcast(payload)
        })
        .then(() => this.getCompanies())
        .then(() => this.onUploadSuccess())
        .catch(err => this.onUploadError(err))
      
    },
    onUploadSuccess() {
      this.showLoaderToggle(false);
      this.clearModalData();
      this.onUpdateReportSuccess();
    },
    onUploadError(err) {
      this.getCompanies();
      this.showLoaderToggle(false);
      console.log(JSON.stringify(err.response));
      this.showErrorAlert(err.response.data.message || err.message);
    },
    clearErrors() {
      this.showCompanyError = false;
      this.showMethodologyError = false;
      this.showCurrencyError = false;
    },
    validateInput() {
      let isAnyError = false;
      this.clearErrors();
      if (!this.company) {
        this.showCompanyError = true;
        isAnyError = true;
      }
      if (!this.methodology) {
        this.showMethodologyError = true;
        isAnyError = true;
      }
      if (!this.currency) {
        this.showCurrencyError = true;
        isAnyError = true;
      }
      return !isAnyError;
    }
  }
}
</script>
<style>
</style>