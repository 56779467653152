export const BUCKET_DETAILS = {
  bucketName: "innovation-sprint-s3-bucket",
  bucketRegion: "us-east-1",
  IdentityPoolId: "us-east-1:c8658b9c-5c3d-4ce1-8cbc-b93c09a7975b",
};

export const REPORT_TYPES = {
  MONTLY: 'Monthly',
  ANNUAL: 'Annual',
  QUATERLY: 'Quaterly'
};

export const API_HOST = "https://cab8lzmjch.execute-api.us-east-1.amazonaws.com/dev";