import axios from 'axios';
import { API_HOST } from '@/constants';
import { getAPIHost } from './apihost';

export function getReportDownloadURL(fileKey) {
  return axios.get(API_HOST + '/download_file/?file_name=' + fileKey);
}

export function getReportUploadURL(payload) {
  return axios.post(API_HOST + '/upload_statement/?file_name=', payload);
}

export function getReportUpdateURL(fileKey, companyId, companyName) {
  return axios.get(API_HOST + '/update_statement/?file_name=' + 
        fileKey + '&company_id=' + companyId + '&company_name=' + companyName);
}

export function uploadCompanyReport(url, file) {
  return axios.put(url, file,  {
    headers: {
        'Content-Type': file.type
      }
    });
}

export function uploadConfigFile(fileKey, file) {
  var form = new FormData();
  form.append("file", file);
  form.append("file_name", fileKey);
  return axios.post(getAPIHost() + '/update_config/', form ,  {
    headers: {
      responseType: 'blob'
    }
  });
}

export function updateDatabase(payload) {
  return axios.post(getAPIHost() + '/update_database/', payload);
}

export function updateDatabaseForcast(payload) {
  return axios.post(getAPIHost() + '/update_database_forecast/', payload);
}

export function getCompletedReports() {
   return axios.get(getAPIHost() + '/getprocessedcompanyinfo');
}

export function getExpandedCompletedReports(companyId) {
  return axios.get(getAPIHost() + '/getdetailsbycomapnyid/' + companyId);
}

export function getInProgressReports() {
  return axios.get(getAPIHost() + '/getinprogresscompanyinfo');
}

export function getDownloadReportURL(fileName) {
  return axios.get(getAPIHost() + '/getdownloadurl/?file_name=' + fileName); 
}

export function getUploadReportURL(payload) {
  return axios.post(getAPIHost() + '/upload_statement/', payload); 
}

export function getUpdateReportURL(fileName) {
  return axios.get(getAPIHost() + '/update_statement/?file_name=' + fileName); 
}

export function uploadQuaterlyTemplate(transactionId, companyId, fileName) {
  return axios.get(getAPIHost() + '/uploadquaterlytemplate/?upload_report_id=' + transactionId +
    '&company_id=' + companyId + '&file_name=' + fileName);
}

export function getPeerCompanyReport(payload) {
  return axios.post(getAPIHost() + '/v1/corpdata/peer-report/', payload); 
}