export function buildCompanyDetail(companyObj, companyInfo) {
    let company = companyInfo.companies.find(company => company.companyId === companyObj.id);
    let sector = companyInfo.sectors.find(sector => sector.sectorId === company.sectorId);

    return {
        company_id:  companyObj.id,
        company_name: companyObj.name,
        sector: sector.sectorName,
        currency: company.currency
    };
}