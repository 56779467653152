<template>
  <div id="root" class="retrieval-container">
    <Tabs>
      <Tab name="Custom Query" :selected="true">
        <div>
          
          <span style="display: flex; font-weight: bold"> Step 1: Select company(s) </span>
            <div id="company-table">
              <div>
                <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
                <span style="padding-left: 5px;"> Please select either <b> Company Name </b> or <b> Methodology </b> or <b> Ratio </b> to search for company(s)</span>
              </div>
              <div>
                  <div class="label-div">
                      <label > Query Type </label>
                    </div>
                     
                <div id="query-type-div">
                    <form class="mds-form mds-form--medium-layout" method="post" action="form/processor"
                      style="width: 100%; margin: 0 auto;">
                      <div class="mds-form__field-group">
                          <fieldset class="mds-form__radio-button-group" role="radiogroup">
                  
                              <label class="mds-form__radio-button" for="name-radio">
                                  <input id="name-radio" name="name-radio" value="name" type="radio" 
                                    class="mds-form__radio-button-input" 
                                    v-model="queryType"
                                    @click="clearAll"/>
                                  <span class="mds-form__radio-button-visible-wrap">
                                      <span class="mds-form__radio-button-visual"></span>
                                      <span class="mds-form__radio-button-text ">
                                          Company Name 
                                      </span>
                                  </span>
                              </label>
                              <label class="mds-form__radio-button" for="methodology-radio">
                                  <input id="methodology-radio" name="methodology-radio" value="methodology" type="radio" 
                                    v-model="queryType" class="mds-form__radio-button-input" 
                                    @click="clearAll"/>
                                  <span class="mds-form__radio-button-visible-wrap">
                                      <span class="mds-form__radio-button-visual"></span>
                                      <span class="mds-form__radio-button-text ">
                                          Methodology 
                                      </span>
                                  </span>
                              </label>
                              <label class="mds-form__radio-button" for="ratio-radio">
                                  <input id="ratio-radio" name="ratio-radio" value="ratio" type="radio"  
                                    v-model="queryType" class="mds-form__radio-button-input" 
                                    @click="clearAll"/>
                                  <span class="mds-form__radio-button-visible-wrap">
                                      <span class="mds-form__radio-button-visual"></span>
                                      <span class="mds-form__radio-button-text ">
                                          Ratio
                                      </span>
                                  </span>
                              </label>
                          </fieldset>
                      </div>
                    </form>
                  </div>
                </div>
                <div v-if="queryType === 'name' || queryType === 'methodology'"> 
                  <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px;"> Please select minimum 1 company and maximum 20 companies </span>
                </div>
                <div v-if="queryType === 'ratio'"> 
                  <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
                  <span style="padding-left: 5px;"> Please select a maximum of 3 ratios </span>
                </div>
                <div v-if="queryType === 'name'">
                    <div class="label-div">
                      <label > Company </label>
                    </div>
                    <div>
                      <Multiselect v-model="value" :options="methodologies" :multiple="true" 
                        group-values="companies" group-label="name" :group-select="true"
                        :closeOnSelect="false"
                        placeholder="Select one or more companies" track-by="name" label="name"
                        selectGroupLabel="Select methodology"
                        selectLabel="Select company"
                        deselectGroupLabel="Remove methodology"
                        deselectLabel="Remove company"
                        :class="{ 'error': showAllCompanyError}">
                            <span slot="noResult">
                                No elements found. Consider changing the search query.
                            </span>
                        </Multiselect>
                        <div v-if="showAllCompanyError">
                          <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                          <span style="padding-left: 5px; color: red"> Please select atleast 1 company </span>
                        </div>
                    </div>
                </div>

                <div v-if="queryType === 'methodology'">
                    <div class="label-div">
                      <label > Methodology </label>
                    </div>
                    <div>
                      <Multiselect v-model="value" label="name" track-by="code" 
                          :options="methodologies" :multiple="true"
                          :closeOnSelect="false"
                          selectLabel="Select methodology"
                          deselectLabel="Remove methodology"
                          placeholder="Select one or more methodologies"
                          :class="{ 'error': showMethodologyError}"
                        >
                      </Multiselect>
                      <div v-if="showMethodologyError">
                          <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                          <span style="padding-left: 5px; color: red"> Please select atleast 1 methodology </span>
                        </div>
                    </div>
                </div>
                <div v-if="queryType === 'ratio'">
                    <div class="label-div">
                      <label > Financial Items </label>
                    </div>
                    <div>
                     <Multiselect v-model="value" label="name" track-by="code" 
                          :closeOnSelect="false"
                          :options="ratiosFilter" :multiple="true"
                          placeholder="Select one or more ratios"
                          selectLabel="Select ratio"
                          deselectGroupLabel="Remove ratio"
                          :class="{ 'error': showRatiosError}"
                          
                        >
                      </Multiselect>
                      <div v-if="showRatiosError">
                          <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                          <span style="padding-left: 5px; color: red"> Please select atleast 1 ratio </span>
                        </div>
  
                    </div>
                </div>
                <div v-if="queryType === 'ratio'" class="ratios-container">
                  <div v-for="ratio in value" :key="ratio.code" class="ratios-table"
                      >
                      <div  class="label-div">
                        <label> {{ ratio.name }} </label>
                      </div>
                      <div>
                        <div>
                          <Multiselect label="name" track-by="code"
                            :id="ratio.code" 
                            :options="ratio.options"
                            v-model="ratio.value"
                            placeholder="Select a range"
                            selectLabel="Select range"
                            deselectLabel="Remove range"
                            :class="{ 'error': ratio.showError}">
                          </Multiselect>
                          <div v-if="ratio.showError">
                            <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                            <span style="padding-left: 5px; color: red"> Please select a range </span>
                          </div>
   
                        </div>
                      </div>   
                  </div>
                              
            </div>
            <div style="padding-top: 20px; padding-bottom: 20px;" class="search"
                v-if="queryType === 'ratio'">
                <button @click="search"> Search Companies </button>
            </div> 
              <div v-if="queryType === 'ratio' && companies.length > 0" style="width: 100% !important; display: block !important">
                  <div style="width: 100%"> 
                    <span> <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i> </span>
                    <span style="padding-left: 5px;"> Please select a maximum of 20 companies </span>
                  </div>
                  <div style="display: flex; width: 100%;">
                      <div class="label-div" style="width: 10% !important">
                        <label > Company </label>
                      </div>
                      <div style="width: 24% !important">
                        <Multiselect v-model="selectedCompanies" :options="companies" :multiple="true" 
                          group-values="companies" group-label="name" :group-select="true"
                          :closeOnSelect="false"
                          placeholder="Select one or more companies" track-by="name" label="name"
                          selectGroupLabel="Select methodology"
                          selectLabel="Select company"
                          deselectGroupLabel="Remove methodology"
                          deselectLabel="Remove company"
                          :class="{ 'error': showSearchCompanyError}">
                              <span slot="noResult">
                                  No elements found. Consider changing the search query.
                              </span>
                          </Multiselect>
                          <div v-if="showSearchCompanyError">
                            <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                            <span style="padding-left: 5px; color: red"> Please select atleast 1 company </span>
                          </div>
                      </div>
                       
                  </div>
          </div>
                <div class="company-container" v-if="queryType === 'methodology'">
                  <div colspan="1" :key="val.code" v-for="val in value">
                          <div>
                            <label > {{ val.name }} </label>
                          </div>
                          <div>
                          <Multiselect :id="val.code" v-model="val.selectedCompanies" label="name" track-by="code" 
                              :options="[allOption].concat(val.companies)" :multiple="true"
                              :closeOnSelect="false"
                              placeholder="Select one or more companies"
                              selectLabel="Select company"
                              deselectLabel="Remove company"
                              @select="checkSelectAll"
                              @remove="checkRemoveAll"
                              :class="{ 'error': val.showError}"
                          >
                          </Multiselect>
                          <div v-if="val.showError">
                            <span > <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i> </span>
                            <span style="padding-left: 5px; color: red"> Please select atleast 1 company </span>
                          </div>
                      
                          </div>
                      
                  </div>
                </div>
                </div>
        </div>
        
        <div v-if="queryType === 'name'">
          <CompanyRetrieval
            :allOption="allOption"
            :methodologies="methodologies"
            :years="years"
            :ratios="ratios"
            :denominations="denominations"
            :lineItems="lineItems"
            :companyInfo="companyInfo"
            :step1Completed="step1Completed" 
            :value="value"
            :validate="validate"
            :clearAll="clearAll"
            :showErrorAlert="showErrorAlert"
            :showLoaderToggle="showLoaderToggle"/>
        </div>
        <div v-if="queryType === 'methodology'">
          <MethodologyRetrieval 
            :allOption="allOption"
            :years="years"
            :ratios="ratios"
            :denominations="denominations"
            :lineItems="lineItems"
            :companyInfo="companyInfo"
            :step1Completed="step1Completed"
            :value="value"
            :validate="validate"
            :clearAll="clearAll"
            :showErrorAlert="showErrorAlert"
            :showLoaderToggle="showLoaderToggle"/>
        </div>
        <div v-if="queryType === 'ratio'"> 
          <Ratios
            :allOption="allOption"
            :companies="companies"
            :years="years"
            :ratios="ratios"
            :denominations="denominations"
            :lineItems="lineItems"
            :companyInfo="companyInfo"
            :step1Completed="step1Completed"
            :value="value"
            :selectedCompanies="selectedCompanies"
            :validate="validate"
            :clearAll="clearAll"
            :showErrorAlert="showErrorAlert"
            :showLoaderToggle="showLoaderToggle"/>
        </div>
      </Tab>
      <Tab name="Peer Group">
        <PeerComp 
          :methodologies="methodologies"
          :showLoaderToggle="showLoaderToggle"
          :showErrorAlert="showErrorAlert"
        />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from './Tabs.vue';
import Tab from './Tab.vue';
import CompanyRetrieval from './CompanyRetrieval';
import MethodologyRetrieval from './MethodologyRetrieval';
import Ratios from './Ratios';
import PeerComp from './PeerComp';
import Multiselect from 'vue-multiselect';
import { getCorpDataLoad, retrieveFinancialCorpDataReport } from '@/apis/corpdata';
import { searchCompanies } from '@/apis/companies';
import { map, compact, uniq } from 'lodash';

export default {
  name: 'Retrieval',
  data() {
    return {
      queryType: '',
      value: [

      ],
      showAllCompanyError: false,
      showMethodologyError: false,
      showRatiosError: false,
      showSearchCompanyError: false,
      companies: [],
      selectedCompanies: [],
      methodologies: [
        
      ],
      years: [
          {
              name: 2016, code: 2016
          },
          {
              name: 2017, code: 2017
          },
          {
              name: 2018, code: 2018
          },
          {
              name: 2019, code: 2019
          },
          {
              name: 2020, code: 2020
          }
          
      ],
      denominations: [
        {
            name: 'Millions', code: 'millions'
        },
        {
            name: 'Thousands', code: 'thousands'
        }
      ],
      yearsSelected: [

      ],
      lineItems: [
        
      ],
      ratios: [],
      allOption: {
        name: 'All',
        code: 'all'
      },
      ratiosFilter: [
      
        {
          name: 'Lease-adjusted Debt/Capital',
          code: 'lease_adjusted_debt_capital',
          range: true,
          field: 'ratio',
          options: [{
            code: [0.01, 0.25],
            name: '1% - 25%'
          },
          {
            code: [0.25, 0.50],
            name: '25% - 50%'
          },
          {
            code: [0.5, 0.75],
            name: '50% - 75%'
          },
          {
            code: [0.75],
            name: '75% and above'
          }],
          value: null
        },
        {
          name: 'Lease-adjusted cash flow/debt',
          code: 'lease_adjusted_cash_flow_debt',
          range: true,
          percent: true,
          field: 'ratio',
          options: [{
            code: [0.01, 0.25],
            name: '1% - 25%'
          },
          {
            code: [0.25, 0.50],
            name: '25% - 50%'
          },
          {
            code: [0.5, 0.75],
            name: '50% - 75%'
          },
          {
            code: [0.75],
            name: '75% and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'Lease-adjusted debt / EBITDA',
          code: 'lease_adjusted_debt_EBITDA',
          field: 'ratio',
          range: true,
          options: [{
            code: [1, 2],
            name: '1x - 2x'
          },
          {
            code: [2, 3],
            name: '2x - 3x'
          },
          {
            code: [3, 4],
            name: '3x - 4x'
          },
          {
            code: [4, 5],
            name: '4x - 5x'
          },
          {
            code: [6, 7],
            name: '6x - 7x'
          },
          {
            code: [7],
            name: '7x and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'Lease-adjusted EBITDA interest coverage',
          code: 'lease_adjusted_EBITDA_interest_coverage',
          range: true,
          field: 'ratio',
          options: [{
            code: [1, 2],
            name: '1x - 2x'
          },
          {
            code: [2, 3],
            name: '2x - 3x'
          },
          {
            code: [3, 4],
            name: '3x - 4x'
          },
          {
            code: [4, 5],
            name: '4x - 5x'
          },
          {
            code: [6, 7],
            name: '6x - 7x'
          },
          {
            code: [7],
            name: '7x and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'Lease-adjusted EBIT interest coverage',
          code: 'lease_adjusted_EBIT_interest_coverage',
          range: true,
          field: 'ratio',
          options: [{
            code: [1, 2],
            name: '1x - 2x'
          },
          {
            code: [2, 3],
            name: '2x - 3x'
          },
          {
            code: [3, 4],
            name: '3x - 4x'
          },
          {
            code: [4, 5],
            name: '4x - 5x'
          },
          {
            code: [6, 7],
            name: '6x - 7x'
          },
          {
            code: [7],
            name: '7x and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'Revenue',
          code: 'revenue',
          field: 'income_statement',
          range: true,
          options: [{
            code: [50, 100],
            name: '$50M - $100M'
          },
          {
            code: [101, 200],
            name: '$101M - $200M'
          },
          {
            code: [201],
            name: '$201M and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'EBITDA',
          code: 'EBITDA',
          field: 'income_statement',
          range: true,
          options: [{
            code: [5, 30],
            name: '$5M - $30M'
          },
          {
            code: [31, 60],
            name: '$31M - $60M'
          },
          {
            code: [61, 100],
            name: '$61M - $100M'
          },
          {
            code: [100],
            name: '$100M and above'
          }],
          value: null,
          showError: false
        },
        {
          name: 'EBITDA margin range',
          code: 'EBITDA_margin_range',
          field: 'ratio',
          range: true,
          percent: true,
          options: [{
            code: [0.01, 0.1],
            name: '1% - 10%'
          },
          {
            code: [0.11, 0.2],
            name: '11% - 20%'
          },
          {
            code: [0.21, 0.3],
            name: '21% - 30%'
          },
          {
            code: [0.31],
            name: '31% and above'
          }],
          value: null,
          showError: false
        }
      ],
      lineItemsSelected: [],
      selectAllSelected: false,
      methodologyForSelectAll: null,
      removeAllSelected: false,
      methodologyForRemoveAll: null
    };
  },
  props: ['showErrorAlert', 'changeActiveTab', 'toggleRestrictedAccess', 'showLoaderToggle', 'companyInfo'],
  components: {
      Tabs,
      Tab,
      CompanyRetrieval,
      MethodologyRetrieval,
      Ratios,
      PeerComp,
      Multiselect
  },
  mounted() {
    this.changeActiveTab({
      name: 'Reporting Tool',
      routerLink: '/reporting-tool'
    });
    getCorpDataLoad()
      .then(res => {
         let response = res.data;
         let sectors = response.methodologies.sectors;
         let companies = response.methodologies.companies;
         sectors.forEach(sector => {
           let methodologyForUI = {};
            methodologyForUI.id = sector.sector_id;
            methodologyForUI.name = sector.sector;
            methodologyForUI.code = sector.sector;
            methodologyForUI.companies = [];
            companies.filter(c => c.sector_id === sector.sector_id).forEach(company => {
              methodologyForUI.companies.push({
                id: company.company_id,
                name: company.company,
                code: company.company,
              });
            })
            methodologyForUI.selectedCompanies = [];
            methodologyForUI.showError = false;
            this.methodologies.push(methodologyForUI);
         });
         this.populateLineItem(this.removeDuplicates(response.balance_sheet), 'Balance Sheet');
         this.populateLineItem(this.removeDuplicates(response.cash_flow), 'Cash Flow Statement');
         this.populateLineItem(this.removeDuplicates(response.income_stmt), 'Income Statement');
         this.populateRatio(response.ratio);
      })
      .catch(err => {
        console.log(JSON.stringify(err.response))
        if (err.response.status === 403) {
          this.toggleRestrictedAccess(true);
        } else {
          this.showErrorAlert(err.response.data.message);
        }
      });
  },
  computed: {
    step1Completed: function() {
      if (this.queryType === 'methodology') {
        return this.value.length > 0 && this.methodologies.filter(methodology => {
          return methodology.selectedCompanies && methodology.selectedCompanies.length > 0;
        }).length === this.value.length;
      }  if (this.queryType === 'name') {
        return this.value && this.value.length > 0;
      } if (this.queryType === 'ratio') {
        return (this.value && this.value.length > 0) && 
             (this.value.find(val => val.value === null) === undefined) && 
             (this.companies && this.companies.length > 0) && 
             (this.selectedCompanies && this.selectedCompanies.length > 0);
      } else {
        return false;
      }
    }
  },
  watch: {
    methodologies: {
      handler: function () {
        if (this.selectAllSelected) {
          this.methodologyForSelectAll.selectedCompanies = this.methodologyForSelectAll.companies;
          this.selectAllSelected = false;
          this.methodologyForSelectAll = null;
        }
        if (this.removeAllSelected) {
          this.methodologyForRemoveAll.selectedCompanies = [];
          this.removeAllSelected = false;
          this.methodologyForRemoveAll = null;
        }
      },
      deep: true,
    },
    value: {
      handler: function (after) {
        let vm = this;
        vm.methodologies.forEach(methodology => {
          if (!after.find(val => methodology.name === val.name)) {
            methodology.selectedCompanies = [];
            methodology.showError = false;
          }
        });
        this.companies = [];
        this.selectedCompanies = [];
        this.ratios.forEach(ratio => {
          if (!after.find(val => ratio.name === val.name)) {
            ratio.value = null;
            ratio.showError = false;
          }
        });
      },
      deep: true,
    }
  },
  methods: {
    removeDuplicates(items) {
      return uniq(items);
    },
    checkSelectAll(option, id) {
      if(option.code === 'all') {
        this.selectAllSelected = true;
        this.methodologyForSelectAll = this.methodologies.find(opt => opt.code === id);
      } else {
        this.removeAllSelected = false;
        this.methodologyForRemoveAll = null;
      }
    },
    checkRemoveAll(option, id) {
      if(option.code === 'all') {
        this.removeAllSelected = true;
        this.methodologyForRemoveAll = this.methodologies.find(opt => opt.code === id);
      } else {
        this.removeAllSelected = false;
        this.methodologyForRemoveAll = null;
      }
    },
    populateLineItem(lineItemArr, lineItemName) {
      let lineItemObj = {
          name: lineItemName,
          code: lineItemName,
          items: [
            
          ]
      };
      lineItemArr.forEach(lineItem => {
        lineItemObj.items.push({
          name: lineItem,
          code: lineItem
        });
      });
      this.lineItems.push(lineItemObj);
    },
    populateRatio(ratioResponse) {
      this.ratios = ratioResponse.map(ratio => {
        return {
          code: ratio,
          name: ratio
        }
      })
    },
    validate() {
      this.clearErrors();
      let isCompanyError = false;
      if (this.queryType === 'name') {
        if (this.value && this.value.length === 0) {
          this.showAllCompanyError = true;
          isCompanyError  = true;
        }
      } 
      if (this.queryType === 'methodology') {
        if (this.value && this.value.length === 0) {
          this.showMethodologyError = true;
          isCompanyError  = true;
        } else {
          this.value.forEach(val => {
            if(val.selectedCompanies && val.selectedCompanies.length === 0) {
              val.showError = true;
              isCompanyError = true;
            }
          })
        }
      }
      if (this.queryType === 'ratio') {
        if (!this.validateSearchCompanies()) {
          isCompanyError  = true;
        }
        if (this.selectedCompanies && this.selectedCompanies.length === 0) {
          this.showSearchCompanyError  = true;
          isCompanyError  = true;
        }
      } 
      return isCompanyError;
    },
    clearErrors()  {
      this.showAllCompanyError = false;
      this.showMethodologyError = false;
      this.showRatiosError = false;
      this.showSearchCompanyError = false;
      this.value.forEach(val => {
          val.showError = false;
    
      })
    },
    validateSearchCompanies () {
      let validateRatios = this.value && this.value.length > 0;
      if (!validateRatios) {
        this.showRatiosError = true;
        return false;
      }
      let anyRatioError = false;
      this.value.forEach(ratio => {
        if (ratio.value === null) {
          ratio.showError = true;
          anyRatioError = true;
        }
      })
      if(anyRatioError) {
        return false;
      }
      return true;
    },
    search() {
      this.clearErrors();
      this.companies = [];
      if(!this.validateSearchCompanies()) {
        return;
      }
      let searchCompanyPayload = {
        ratio: {},
        income_statement: {}
      }
      this.value.forEach(ratio => {
        if (ratio.value) {
          let searchField = searchCompanyPayload[ratio.field];
          searchField[ratio.code] = ratio.value.code;
        }
      });
      
      searchCompanies(searchCompanyPayload)
        .then(res => {
          if (res.data.length === 0) {
            this.showErrorAlert('No companies matching search criteria');
          } else {
            let allSectors = uniq(map(res.data, 'Methodology'));
            allSectors.forEach(sec => {
              let methodology = {};
              methodology.code = sec;
              methodology.name = sec;
              methodology.companies = res.data.filter(comp => comp.Methodology === sec).map(c => {
                return {
                  id: c.company_id,
                  code: c.company_name,
                  name: c.company_name
                }
              });
              this.companies.push(methodology);
            })
          }
        })
        .catch(err => {
          console.log(JSON.stringify(err.response))
          this.showErrorAlert(err.response.data.message);
        });
    },
    clearAll() {
      this.value = [];
      this.clearErrors();
    },
    retrieve() {
      let payload = {
        "methodolody": {},
        "year": [],
        "is": [],
        "bs": [],
        "cf": []
      };
      this.value.forEach(selectedMethodology => {
        payload.methodolody[selectedMethodology.name] = 
          compact(map(selectedMethodology.selectedCompanies, 'id'));
      });
      payload['year'] = map(this.yearsSelected, 'name');
      this.lineItems.forEach(lineItem => {
        if (lineItem.name === 'Income Statement') {
          payload['is'] = map(lineItem.selectedLineItems, 'name');
        }
        if (lineItem.name === 'Balance Sheet') {
          payload['bs'] = map(lineItem.selectedLineItems, 'name');
        }
        if (lineItem.name === 'Cash Flow Statement') {
          payload['cf'] = map(lineItem.selectedLineItems, 'name');
        }
      })
      retrieveFinancialCorpDataReport(payload)
        .catch(err => {
            console.log(JSON.stringify(err.response))
            this.showErrorAlert(err.response.data.message);
          });
    }
  }
}
</script>>
<style>
 .retrieval-container {
     margin-left: 25px
 }
</style>