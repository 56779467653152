<template>
  <div class="mds-header mds-header--level-3 mre-padding">
    <h2 class="mds-header__title">Page not Found</h2>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
};
</script>

<style>
.mre-padding {
  padding: 32px;
}
</style>
