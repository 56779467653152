<template>
    <div style="margin: 0 auto; display: inline-block; width: 66%">
        <form class="mds-form mds-form--medium-layout" method="post" action="form/processor"
            style="width: 100%; margin: 0 auto;">
            <div class="mds-form__field-group mds-form__field-group--horizontal">
                <fieldset class="mds-form__radio-button-group" role="radiogroup"
                    style="width: 100%">
        
                    <label class="mds-form__radio-button" for="input-report-radio">
                        <input id="input-report-radio" name="input-report-radio" value="input-report" type="radio" 
                        class="mds-form__radio-button-input" 
                        v-model="inputSelection"/>
                        <span class="mds-form__radio-button-visible-wrap">
                            <span class="mds-form__radio-button-visual"></span>
                            <span class="mds-form__radio-button-text ">
                                Input Company Report
                            </span>
                        </span>
                    </label>
                    <label class="mds-form__radio-button" for="upload-forcasting-radio">
                        <input id="upload-forcasting-radio" name="upload-forcasting-radio" value="upload-forcasting" type="radio" 
                        v-model="inputSelection" class="mds-form__radio-button-input" />
                        <span class="mds-form__radio-button-visible-wrap">
                            <span class="mds-form__radio-button-visual"></span>
                            <span class="mds-form__radio-button-text ">
                                Upload Forecasting Tool 
                            </span>
                        </span>
                    </label>
                </fieldset>
            </div>
        </form>

        <div v-if="inputSelection === 'input-report'">
            <InputCompanyReportDetails 
                :companies="companies"
                :methodologies="methodologies"
                :reportTypes="reportTypes"
                :showLoaderToggle="showLoaderToggle"
                :showSuccessAlert="showSuccessAlert"
                :onInputReportSuccess="onInputReportSuccess"
                :showErrorAlert="showErrorAlert"
                :getCompanies="getCompanies"
            />
        </div>

        <div v-if="inputSelection === 'upload-forcasting'">
            <UpdateCompanyReportDetails 
                :companies="companies"
                :methodologies="methodologies"
                :showLoaderToggle="showLoaderToggle"
                :onUpdateReportSuccess="onUpdateReportSuccess"
                :showSuccessAlert="showSuccessAlert"
                :showErrorAlert="showErrorAlert"
                :getCompanies="getCompanies"
            />
        </div>
        </div>
</template>
<script>

import InputCompanyReportDetails from '@/components/modals/InputCompanyReportDetails';
import UpdateCompanyReportDetails from '@/components/modals/UpdateCompanyReportDetails';
export default {
    name: 'InputCompanyReport',
    data() {
        return {
            inputSelection: '',
            showInputModal: false,
            showUpdateModal: false,
            companies: [],
            methodologies: [],
            reportTypes: []
        }
    },
    props: ['showLoaderToggle', 'showSuccessAlert', 'showErrorAlert', 'companyInfo', 
      'onInputReportSuccess', 'onUpdateReportSuccess', 'getCompanies'],
    components: {
        InputCompanyReportDetails,
        UpdateCompanyReportDetails
    },
    watch: {
      companyInfo: function () {
        this.getMethodologiesAndCompanies();
      }
    },
    mounted() {
      this.getMethodologiesAndCompanies();
    },
    methods: {
      getMethodologiesAndCompanies() {
        if (this.companyInfo) {
          this.methodologies = this.companyInfo.sectors;
          this.companies = this.companyInfo.companies;
          this.reportTypes = this.companyInfo.reportType;
        }
      },
      browse(selectedFileElementId, uploadBtnElementId) {
        document.getElementById(selectedFileElementId).innerHTML = '';
        document.getElementById(selectedFileElementId).title = '';
        document.getElementById(uploadBtnElementId).click();
      },
      showSelectedFile(selectedFileElementId, uploadBtnElementId) {
        document.getElementById(selectedFileElementId).innerHTML = 
          document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
        document.getElementById(selectedFileElementId).title = 
          document.getElementById(uploadBtnElementId).files ? document.getElementById(uploadBtnElementId).files[0].name : 'No file selected';
      },
      binArrayToJson(binArray) {
        var str = "";
        for (var i = 0; i < binArray.length; i++) {
          str += String.fromCharCode(parseInt(binArray[i]));
        }
        return JSON.parse(str);
      },
      JsonToArray(json) {
        var str = JSON.stringify(json, null, 0);
        var ret = new Uint8Array(str.length);
        for (var i = 0; i < str.length; i++) {
          ret[i] = str.charCodeAt(i);
        }
        return ret
      }
    }
}
</script>
<style>

</style>