<template>
  <!-- Modal content -->
  <div>
    <SelectYears 
      :showSelectYears="step1Completed"
      :years="years"
      :addSelectedYear="addSelectedYear"
      :showYearsError="showYearsError"/>

    <SelectLineItemsRatios 
      :showSelectLineItemsRatios="step2Completed"
      :lineItems="lineItems"
      :ratios="ratios"
      :allOption="allOption"
      :showLineItemError="showLineItemError"
      :addSelectedRatio="addSelectedRatio"/>

    <SelectDenomination 
      :showSelectDenomination="step3Completed"
      :denominations="denominations"
      :showDenominationError="showDenominationError"
      :addSelectedDenomination="addSelectedDenomination"/>

    <div style="padding-top: 20px; padding-bottom: 20px;">
      <button @click="retrieve"  style="margin-right: 10px"> Retrieve </button>
      <button @click="clear" class="mds-button"> Clear </button>
    </div>
  </div>
</template>
<script>

// import { differenceBy, isEqual } from 'lodash';
import { retrieveFinancialCorpDataReport } from '@/apis/corpdata';
import { map } from 'lodash';
import SelectYears from '@/components/SelectYears';
import SelectLineItemsRatios from '@/components/SelectLineItemsRatios';
import SelectDenomination from '@/components/SelectDenomination';
import { buildCompanyDetail } from '@/utils/commons';

export default {
  name: 'CompanyRetrieval',
  data() {
    return {
      valueRatios: [

      ],
      showYearsError: false,
      showLineItemError: false,
      showDenominationError: false,
      yearsSelected: [

      ],
      denominationSelected: 'millions'
    }
  },
  props: ['allOption', 'methodologies', 'years', 'ratios', 'denominations', 'lineItems', 'companyInfo', 'step1Completed', 
          'value', 'validate', 'clearAll', 'showAllCompanyError', 'showErrorAlert', 'showLoaderToggle'],
  computed: {
    step2Completed() {
      return this.step1Completed && this.yearsSelected && this.yearsSelected.length > 0;
    },
    step3Completed: function() {
      return this.step2Completed && this.lineItems.find(lineItem => {
        return lineItem.selectedLineItems && lineItem.selectedLineItems.length > 0;
      }) !== undefined;
    },
    step4Completed: function() {
      return this.step3Completed && this.denominationSelected !== null
    }
  },
  watch: {
    step1Completed: {
      handler: function () {
        if (!this.step1Completed) {
          this.showYearsError = false;
          this.showLineItemError = false;
          this.showDenominationError = false;
          this.yearsSelected = [];
          this.lineItems.forEach(lineItem => {
            lineItem.selectedLineItems = [];
          });
          this.denominationSelected = 'millions';
        }
        
      }
    },
    step2Completed: {
      handler: function () {
        if (!this.step2Completed) {
          this.showYearsError = false;
          this.showLineItemError = false;
          this.showDenominationError = false;
          this.yearsSelected = [];
          this.lineItems.forEach(lineItem => {
            lineItem.selectedLineItems = [];
          });
          this.denominationSelected = 'millions';
        }
        
      }
    },
    step3Completed: {
      handler: function () {
        if (!this.step3Completed) {
          this.showYearsError = false;
          this.showDenominationError = false;
          this.denominationSelected = 'millions';
        }
      }
    }
  },
  components: { SelectYears, SelectLineItemsRatios, SelectDenomination },
  mounted() {

  },
  methods: {
    clear() {
      this.clearAll();
    },
    validateCompanyRetrieval() {
      this.clearCompanyRetrievalErrors();
      let isAnyError = false;
      if (this.step1Completed && this.yearsSelected && this.yearsSelected.length === 0) {
        this.showYearsError = true;
        isAnyError = true;
      }

      if (this.step2Completed) {
        let lineItemFound = this.lineItems.find(lineItem => {
          return lineItem.selectedLineItems && lineItem.selectedLineItems.length !== 0
        });
        if (!lineItemFound) {
          isAnyError = true;
          this.showLineItemError = true;
        }
      }

      return isAnyError;
      
    },
    clearCompanyRetrievalErrors() {
      this.showYearsError = false;
      this.showLineItemError = false;
      this.showDenominationError = false;
      this.showRatiosError = false;
      this.valueRatios.forEach(ratio => {
        ratio.showError = false;
      });
    },
    validateMaxInput() {
      if (this.value.length > 20) {
        this.showErrorAlert('Please choose maximum 20 companies');
        return false;
      }
      if (this.yearsSelected.length > 2) {
        this.showErrorAlert('Please choose maximum 2 years');
        return false;
      }
      return true;
    },
    addSelectedYear(years) {
      this.yearsSelected = years;
    },
    addSelectedRatio(ratios) {
      this.valueRatios = ratios;
    },
    addSelectedDenomination(denomination) {
      this.denominationSelected = denomination;
    },
    retrieve() {
      let companyError = this.validate();
      let restError = this.validateCompanyRetrieval();
      if (companyError || restError) {
        this.showErrorAlert('Please input the fields marked in red');
        return;
      }
      if(!this.validateMaxInput()) {
        return;
      }
      let payload = {
        "companies": [],
        "year": [],
        "denomination": '',
        "income_stmt": [],
        "balance_sheet": [],
        "cash_flow": [],
        "ratio": []
      };
      this.value.forEach(val => {
        payload['companies'].push((buildCompanyDetail(val, this.companyInfo)));
      });
      payload['year'] = map(this.yearsSelected, 'name');
      payload['denomination'] = this.denominationSelected;
      this.lineItems.forEach(lineItem => {
        if (lineItem.name === 'Income Statement') {
          payload['income_stmt'] = map(lineItem.selectedLineItems, 'name');
        }
        if (lineItem.name === 'Balance Sheet') {
          payload['balance_sheet'] = map(lineItem.selectedLineItems, 'name');
        }
        if (lineItem.name === 'Cash Flow Statement') {
          payload['cash_flow'] = map(lineItem.selectedLineItems, 'name');
        }
      })
      payload['ratio'] = this.valueRatios.map(rat => rat['name']);
      this.showLoaderToggle(true);
      retrieveFinancialCorpDataReport(payload)
        .then((response) => {
          this.showLoaderToggle(false);
          const blob = new Blob([response.data], { type: response.data.type });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Financial_Report.csv');
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          this.showLoaderToggle(false);
          console.log(JSON.stringify(err.response));
          this.showErrorAlert(err.response.data.message || err.message);
        });
    }
  }
}
</script>
<style>

  .multiselect__tags {
    border: 1px solid #bdbdbb !important;
  }  
  
  .multiselect__tags input {
    border: none !important
  }

  #company-table > div {
      text-align: left;
      display: flex;
  }

  #year-table > div {
      text-align: left;
      display: flex;
  }

  .multiselect__content-wrapper {
    width: auto !important;
    min-width: 100%;
  }

  .label-div {
    padding-top: 10px;
  }

  #company-table {
    border: 1px solid #bdbdbb;
    padding: 1%;
    margin-bottom: 20px
  }

  #company-table > div > div:nth-child(2n - 1){
      margin-top: 10px;
      width: 10%;
  }
  
  #company-table > div > div:nth-child(2n){
      margin-top: 10px;
      width: 24%;
  }

  #year-table {
    border: 1px solid #bdbdbb;
    padding: 1%;
    margin-bottom: 20px
  }

  #line-items-table {
    border: 1px solid #bdbdbb;
    padding: 1%
  }

  #year-table > div > div:nth-child(2n - 1){
      margin-top: 10px;
      width: 10%;
  }
  
  #year-table > div > div:nth-child(2n){
      margin-top: 10px;
      width: 24%;
  }

  .company-container {
    display: flex;
    flex-wrap: wrap;
  }

  .company-container > div {
    width: 33% !important;
    display: flex;
  }

  .company-container > div > div:nth-child(2n - 1) {
    width: 33%;
    padding-top: 10px;
    
  }

  .company-container > div > div:nth-child(2n) {
    width: 70%;
    margin-right: 20px
  }

  .line-item-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    text-align: left;
  }

  .line-item-container > div {
    width: 33% !important;
    display: flex;
  }

  .line-item-container > div > div:nth-child(2n - 1) {
    width: 40%;
    padding-top: 10px;
    
  }

  .line-item-container > div > div:nth-child(2n) {
    width: 55%;
    margin-right: 20px
  }

  #methodology-ratios-table > div:nth-child(2n - 1){
      margin-top: 10px;
      width: 10%;
  }
  
  #methodology-ratios-table > div:nth-child(2n){
      margin-top: 10px;
      width: 24%;
  }

</style>